import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../dashboard/dashboard.service';
import { TeamDetailsComponent } from '../details/team-details.component';
import { TeamMember, TeamService } from '../../services/team.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TeamEditComponent } from '../edit/team-edit.component';
import { SubscriptionService } from '../../services/subscription.service';

interface TeamGroup {
  title: string;
  teamMembers: TeamMember[];
}

@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css'],
})
export class TeamListComponent implements OnInit, OnDestroy {
  @Input() isEmployer: boolean;
  loading = true;
  teamMembers: TeamMember[] = [];
  groups: TeamGroup[] = [];
  canAddTeamMembers = false;

  private unsubscribe = new Subject<void>();

  constructor(
    public auth: AuthService,
    private modalService: NgbModal,
    public dashboardService: DashboardService,
    private teamService: TeamService,
    private subscriptionService: SubscriptionService
  ) {}

  createGroups() {
    this.groups = [];

    const active = this.teamMembers.filter(member => member.link.status === 'active');
    const deactivated = this.teamMembers.filter(member => member.link.status === 'inactive');
    const terminated = this.teamMembers.filter(member => member.link.status === 'terminated');

    if (active.length > 0) {
      this.groups.push({
        title: '',
        teamMembers: active,
      });
    }

    if (deactivated.length) {
      this.groups.push({
        title: 'DEACTIVATED',
        teamMembers: deactivated,
      });
    }

    if (terminated.length) {
      this.groups.push({
        title: 'TERMINATED',
        teamMembers: terminated,
      });
    }
  }

  async getTeamMembers() {
    this.loading = true;

    this.subscriptionService.canAddTeamMembers$.subscribe(canAddTeamMembers => {
      this.canAddTeamMembers = canAddTeamMembers;
    });

    this.auth.role.subscribe(async role => {
      if (this.auth.user?.uid) {
        if (role === 'employer') {
          this.teamService.helpers$.subscribe(helpers => {
            this.teamMembers = helpers;
            this.createGroups();

            this.loading = false;
          });
        } else {
          this.teamService.employers$.subscribe(employers => {
            this.teamMembers = employers;
            this.createGroups();

            this.loading = false;
          });
        }
      }
    });
  }

  ngOnInit(): void {
    this.teamService.teamMembersUpdate$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      void this.getTeamMembers();
    });

    void this.getTeamMembers();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  addTeamMember() {
    const modal = this.modalService.open(TeamEditComponent, { size: 'lg', scrollable: true });
    modal.componentInstance.isNew = true;
  }

  showTeamMember(member: TeamMember) {
    const modal = this.modalService.open(TeamDetailsComponent, { size: 'xl', scrollable: true });
    modal.componentInstance.isEmployer = this.isEmployer;
    modal.componentInstance.member = member;
  }

  onClose() {
    this.dashboardService.setFeature('');
  }
}
