import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormatCurrency]',
})
export class FormatCurrencyDirective {
  constructor(private elementRef: ElementRef, private control: NgControl) {}

  @HostListener('blur') onBlur() {
    const value = this.control.value;
    if (value) {
      const num = parseFloat(value.replace(/[^0-9.-]+/g, ''));
      if (!isNaN(num)) {
        this.control.control.setValue(num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
      }
    }
  }
}
