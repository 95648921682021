import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../services/modal.service';
import { TeamMember, TeamService } from '../../services/team.service';
import moment from 'moment/moment';
import { LinkService } from '../../services/link.service';
import { PaystubCreateComponent } from '../paystub-create/paystub-create.component';

@Component({
  selector: 'app-paystub-recurring',
  templateUrl: './paystub-recurring.component.html',
  styleUrls: ['./paystub-recurring.component.css'],
})
export class PaystubRecurringComponent implements OnInit {
  @Input() member: TeamMember;

  constructor(
    private modalService: NgbModal,
    private modal: ModalService,
    private linkService: LinkService,
    private teamService: TeamService
  ) {}

  ngOnInit(): void {}

  get nextPayStubDate() {
    const today = moment().weekday() + 1;
    const days = this.member.link.wageSchedule?.weekday <= today ? 7 : 0;
    const nextPayStubDate = moment().day(this.member.link.wageSchedule?.weekday - 1 + days);
    return nextPayStubDate.format('M/DD');
  }

  onCreateOneOffClick() {
    this.modalService.dismissAll();

    const modal = this.modalService.open(PaystubCreateComponent, { size: 'md', scrollable: true });
    modal.componentInstance.member = this.member;
  }

  async onCancelRecurringClick() {
    const result = await this.modal.showConfirmation(
      'Confirm Cancel',
      'Are you sure you want to cancel this recurring paystub?',
      'Yes',
      'No'
    ).result;

    if (result) {
      await this.linkService.deleteWageSchedule(this.member.linkId);
      this.teamService.updateTeamMemberLocally({
        ...this.member,
        link: {
          ...this.member.link,
          wageSchedule: null,
        },
      });
      this.modalService.dismissAll();
    }
  }

  close() {
    this.modalService.dismissAll();
  }
}
