import { Component } from '@angular/core';
import { fadeAnimation } from './animations';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService, Role } from './services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileDialogComponent } from './profile/profile-dialog.component';

declare let gtag: (...args: any[]) => any;
declare let fbq: (...args: any[]) => any;
declare let uetq: Array<any>;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation],
})
export class AppComponent {
  env = !environment.production ? environment.name : '';
  copyrightYear = new Date().getFullYear();
  url: string;
  isEmployer = true;
  modal;

  constructor(private router: Router, public auth: AuthService, private modalService: NgbModal) {
    router.events.subscribe(y => {
      if (y instanceof NavigationEnd) {
        this.url = y.url;
        gtag('config', 'AW-850295643', { page_path: y.url });
        fbq('track', 'PageView');
        uetq.push('event', 'PageView', { el: 'url', ev: y.url });

        if (y.url.includes('dashboard(profile')) {
          this.modal?.close();
          this.modal = this.modalService.open(ProfileDialogComponent, { size: 'lg', scrollable: true });
        }
      }
    });

    this.auth.role.subscribe(role => {
      this.isEmployer = role === Role.employer;
    });
  }

  openUserDialog() {
    this.modalService.open(ProfileDialogComponent, { size: 'lg', fullscreen: 'lg', scrollable: true });
  }
}
