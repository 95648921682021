import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { MasterComponent } from './master.component';
import { DetailsComponent } from './details.component';

@Component({
  selector: 'app-master-details',
  templateUrl: './master-details.component.html',
  styleUrls: ['./master-details.component.css'],
})
export class MasterDetailsComponent {
  @ContentChildren(MasterComponent) masters: QueryList<MasterComponent>;
  @ContentChildren(DetailsComponent) details: QueryList<DetailsComponent>;
  @Input() feature: string;
}
