import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public feature: string;

  constructor(private ngZone: NgZone) {}

  public scrollToTop() {
    this.ngZone.runOutsideAngular(() => {
      window.requestAnimationFrame(() => {
        const container = document.querySelector('.scroll-root');
        if (container) {
          container.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    });
  }

  public setFeature(feature: string) {
    this.feature = feature;

    if (feature !== '') {
      this.scrollToTop();
    }
  }
}
