import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Input() actionText: string;
  @Input() actionLink: string;
  @Input() actionFunction: () => void;
  @Input() closeable: boolean;
  @Input() onClose: () => void;

  constructor() {}

  ngOnInit(): void {}

  onActionButtonClick() {
    if (this.actionFunction) {
      this.actionFunction();
    }
  }
}
