import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-master',
  template: `
    <ng-template #master>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class MasterComponent implements AfterViewInit {
  private _template = new BehaviorSubject<TemplateRef<any>>(null);
  readonly template$ = this._template.asObservable();

  @ViewChild('master', { static: true }) set template(value: TemplateRef<any>) {
    this._template.next(value);
  }

  ngAfterViewInit() {
    this._template.next(this._template.getValue());
  }
}
