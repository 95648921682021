import { Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { TeamMember, TeamService } from '../../services/team.service';
import { PaystubService, YtdHours } from '../../services/paystub.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamMemberPayload, Employment, Link } from '../../services/link.service';
import { GeocodingService } from '../../services/geocoding.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import moment from 'moment-timezone';
import { NgForm, ValidationErrors } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Address as GoogleAddress } from 'ngx-google-places-autocomplete/objects/address';
import { Address, UserService } from '../../services/user.service';
import { formatPhoneNumber, formatSsn, toFloat } from '../../utils/string';
import { take } from 'rxjs/operators';
import { calculatePtoHours, formatDateForInput, formatDateWithTimezone } from '../../utils/date';
import { maskSSN } from '../../utils/input';
import { preventDecimal } from '../../utils/ui';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0px',
          overflow: 'hidden',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'auto',
        })
      ),
      transition('expanded <=> collapsed', [animate('400ms ease-in-out')]),
    ]),
  ],
})
export class TeamEditComponent implements OnInit {
  @Input() member: TeamMember = {
    id: '',
    firstName: '',
    lastName: '',
    linkId: '',
    link: {
      status: '',
      employment: {
        isNewHire: true,
        leaveSettingsSet: true,
        autoEmailPaystub: true,
        startOfWeek: 'sunday',
        title: 'Nanny',
        w4: {
          allowancesChild: 0,
          allowancesOther: 0,
          federalAdditionalWithholding: 0,
          federalExempt: false,
          ficaExempt: false,
          futaExempt: false,
          localExempt: false,
          stateAdditionalWithholding: 0,
          stateAllowances: 0,
          stateExempt: false,
          status: 'single',
          taxExempt: false,
        },
      } as Employment,
      profiles: {
        employer: '',
        helper: '',
        oldHelperProfileId: '',
      },
    } as Link,
    phone: '',
    email: '',
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      county: '',
      country: '',
      subLocality: '',
    },
  };
  @Input() isNew = false;
  submitted = false;
  ytdHours: YtdHours;
  isAdvancedWithholdingsOpen = false;
  error = '';
  formattedPhone = '';
  formattedDob = '';
  formattedStartDate = '';
  changingAddress = false;
  changingMailingAddress = false;
  ssn = '';
  locked = false;
  googleOptions: Options = {
    types: ['geocode'],
    componentRestrictions: { country: 'US' },
  } as Options;
  addressComponents = {
    streetNumber: ['street_number'],
    streetName: ['route'],
    county: ['administrative_area_level_2'],
    country: ['country'],
    state: ['administrative_area_level_1'],
    postalCode: ['postal_code'],
    subLocality: ['sublocality_level_1', 'sublocality'],
    city: ['locality'],
    apartment: ['subpremise'],
  };
  profileId: string;
  loading = false;
  minimumWage = 0;
  ssnError: ValidationErrors | null = null;
  year = new Date().getFullYear();
  enableVacationHours = false;
  vacationHours = 1;
  enableSickHours = false;
  sickHours = 1;
  showStateOverride = false;
  requestCatchup = false;
  private _userAddressState = '';
  stateOptIn = false;
  stateOptInAmount = 0;
  protected readonly calculatePtoHours = calculatePtoHours;
  protected readonly preventDecimal = preventDecimal;

  @ViewChild(NgForm) form!: NgForm;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  jobTitles = ['Nanny', 'Care Giver', 'Nanny Share', 'Cleaner', 'Housekeeper', 'Gardener', 'Other'];

  lat: number;
  lng: number;
  fullAddress = '';

  protected readonly toFloat = toFloat;

  constructor(
    private modalService: NgbModal,
    private geocodingService: GeocodingService,
    private teamService: TeamService,
    private userService: UserService,
    private paystubService: PaystubService
  ) {}

  ngOnInit() {
    this.userService
      .getProfile()
      .pipe(take(1))
      .subscribe(({ profile }) => {
        this.userAddressState = profile.address.state;
        this.profileId = profile.id;

        if (!this.isNew) {
          const stateCode = this.userAddressState.toLowerCase();

          if (stateCode === 'ca' || stateCode === 'or') {
            this.stateOptIn = this.member.link.employment.stateConfig?.[stateCode]?.RSPoptIn;
            this.stateOptInAmount = this.member.link.employment.stateConfig?.[stateCode]?.RSPpercentage;
          } else if (stateCode === 'pa') {
            this.stateOptIn = this.member.link.employment.stateConfig?.[stateCode]?.LSToptIn;
            this.stateOptInAmount = this.member.link.employment.stateConfig?.[stateCode]?.LSTAmount;
          } else if (stateCode === 'ny') {
            this.stateOptIn = this.member.link.employment.stateConfig?.[stateCode]?.FLIoptIn;
          }
        }

        this.teamService
          .getMinimumWage(this.userAddressState)
          .pipe(take(1))
          .subscribe(
            res => {
              this.minimumWage = res.wageInfo.wage;
            },
            error => {
              console.error(error);
            }
          );
      });

    if (!this.isNew) {
      this.initMemberData();
    }
  }

  initMemberData() {
    if (this.member.ssnAvailable) {
      this.ssn = 'XXX-XX-' + this.member.ssnAvailable;
    }

    this.formattedPhone = this.member.phone;
    this.formattedDob = this.member.dateOfBirth ? formatDateForInput(new Date(this.member.dateOfBirth)) : '';
    this.formattedStartDate = this.member.link.employment.startDate
      ? formatDateForInput(new Date(this.member.link.employment.startDate))
      : '';

    this.fullAddress = `${this.member.address.street}, ${this.member.address.city}, ${this.member.address.state} ${this.member.address.postalCode}`;

    this.geocodingService.getLatLong(this.fullAddress).subscribe(
      coordinates => {
        this.lat = coordinates.lat;
        this.lng = coordinates.lng;
      },
      error => console.error(error)
    );

    this.paystubService.getYtdHours(this.member.linkId, this.year).then(
      ytdHours => {
        this.ytdHours = ytdHours;
      },
      () => {}
    );

    if (!!this.member.link.employment.vacationTotals?.[this.year]) {
      this.enableVacationHours = true;
      this.vacationHours = this.member.link.employment.vacationTotals[this.year];
    }

    if (!!this.member.link.employment.sickTotals?.[this.year]) {
      this.enableSickHours = true;
      this.sickHours = this.member.link.employment.sickTotals[this.year];
    }
  }

  initializeStateOptIn() {
    const stateOptInConfig = this.getStateOptIn();
    if (stateOptInConfig && stateOptInConfig.inputType !== 'none') {
      this.stateOptInAmount = stateOptInConfig.inputDefault;
    }
  }

  get userAddressState(): string {
    return this._userAddressState;
  }

  set userAddressState(value: string) {
    this._userAddressState = value;
    this.initializeStateOptIn();
  }

  toggleAdvancedWithholdings(): void {
    this.isAdvancedWithholdingsOpen = !this.isAdvancedWithholdingsOpen;
  }

  handleValidationError(error: ValidationErrors) {
    this.ssnError = error;
  }

  close() {
    this.modalService.dismissAll();
  }

  formatAddress(address: Address) {
    return `${address?.street ?? ''}${address?.apartment ? ` ${address?.apartment}, ` : ''}, ${
      address?.city ? `${address.city}, ` : ''
    }${address?.state ? `${address.state} ` : ''}${address?.postalCode ?? ''}`;
  }

  getComponent = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.long_name ?? '';

  getComponentShort = (address: GoogleAddress, types: string[]) =>
    address.address_components.find((c: any) => c.types.some(type => types.includes(type)))?.short_name ?? '';

  handleAddressChange(address: GoogleAddress) {
    this.minimumWage = 0;

    const getComponent = (types: string[]) => this.getComponent(address, types);
    const getComponentShort = (types: string[]) => this.getComponentShort(address, types);

    const streetNumber = getComponent(this.addressComponents.streetNumber);
    const streetName = getComponentShort(this.addressComponents.streetName);

    this.member.address.street = `${streetNumber} ${streetName}`;
    this.member.address.county = getComponent(this.addressComponents.county);
    this.member.address.country = getComponentShort(this.addressComponents.country);
    this.member.address.state = getComponentShort(this.addressComponents.state);
    this.member.address.postalCode = getComponent(this.addressComponents.postalCode);
    this.member.address.subLocality = getComponent(this.addressComponents.subLocality);
    // Addresses in NYC don't have a city, but do have a sublocality
    this.member.address.city = getComponent(this.addressComponents.city) || this.member.address.subLocality;
    this.member.address.apartment = getComponent(this.addressComponents.apartment);
    this.fullAddress = this.formatAddress(this.member.address);

    this.teamService
      .getMinimumWage(this.member.address.state)
      .pipe(take(1))
      .subscribe(
        res => {
          this.minimumWage = res.wageInfo.wage;
        },
        error => {
          console.error(error);
        }
      );
  }

  isStartDateOutsideQuarter(startDateString: string) {
    const startDate = moment(startDateString);
    return startDate.isAfter(moment().endOf('quarter')) || startDate.isBefore(moment().startOf('quarter'));
  }

  doRequestCatchup() {
    void this.teamService
      .requestCatchup(this.member.linkId)
      .pipe(take(1))
      .subscribe(
        () => {},
        error => {
          console.error(error);
        }
      );
  }

  async onSubmit(overrideStateCheck = false) {
    this.error = '';
    this.submitted = true;

    if (!this.form.valid) {
      this.error = 'Please review any errors on the form.';
      return;
    } else {
      this.loading = true;

      this.changingAddress = false;
      this.changingMailingAddress = false;

      this.member.dateOfBirth = this.formattedDob ? formatDateWithTimezone(this.formattedDob) : '';
      this.member.phone = formatPhoneNumber(this.formattedPhone);

      const employmentPayload = {
        ...this.member.link.employment,
        ...this.getStateOptInSelections(),
        startDate: formatDateWithTimezone(this.formattedStartDate),
        leaveSettingsSet: this.enableSickHours || this.enableVacationHours,
        vacationTotals: this.enableVacationHours ? { [this.year]: this.vacationHours } : {},
        sickTotals: this.enableSickHours ? { [this.year]: this.sickHours } : {},
      };

      const teamMemberPayload: TeamMemberPayload = {
        status: 'active',
        ssn: this.ssn.includes('X') ? undefined : formatSsn(this.ssn),
        linkData: {
          employment: employmentPayload,
        },
        flags: {
          overrideStateCheck,
          movePayroll: false,
          catchupCombined: false,
          newHireReport: true,
          setupDirectDeposit: false,
        },
        profile: {
          firstName: this.member.firstName,
          lastName: this.member.lastName,
          phone: this.member.phone,
          email: this.member.email,
          address: this.member.address,
          dateOfBirth: this.member.dateOfBirth,
          ssnAvailable: this.ssn.substring(this.ssn.length - 4),
        },
      } as TeamMemberPayload;

      if (this.isNew) {
        this.teamService
          .createTeamMember(teamMemberPayload)
          .pipe(take(1))
          .subscribe(
            teamMemberRes => {
              this.teamService.addHelperLocally({
                id: teamMemberRes.payload.link.profiles.helper,
                linkId: teamMemberRes.payload.key,
                link: {
                  ...teamMemberRes.payload.link,
                  employment: employmentPayload,
                },
                firstName: teamMemberPayload.profile.firstName,
                lastName: teamMemberPayload.profile.lastName,
                phone: teamMemberPayload.profile.phone,
                email: teamMemberPayload.profile.email,
                address: teamMemberPayload.profile.address,
                dateOfBirth: teamMemberPayload.profile.dateOfBirth,
                ssnAvailable: maskSSN(this.ssn),
              });

              this.member.linkId = teamMemberRes.payload.key;

              if (this.requestCatchup) {
                this.doRequestCatchup();
              }

              this.loading = false;
              this.close();
            },
            error => {
              this.loading = false;
              this.error = error.error.message;
              console.error(`Error creating link for ${this.member.id}`, error);

              if (error.error.message.includes('different state')) {
                this.showStateOverride = true;
              }
            }
          );
      } else {
        this.teamService
          .updateTeamMember(teamMemberPayload, this.member.linkId)
          .pipe(take(1))
          .subscribe(
            teamMemberRes => {
              this.teamService.updateTeamMemberLocally({
                id: this.member.id,
                linkId: this.member.linkId,
                link: {
                  ...this.member.link,
                  employment: employmentPayload,
                },
                firstName: teamMemberPayload.profile.firstName,
                lastName: teamMemberPayload.profile.lastName,
                phone: teamMemberPayload.profile.phone,
                email: teamMemberPayload.profile.email,
                address: teamMemberPayload.profile.address,
                dateOfBirth: teamMemberPayload.profile.dateOfBirth,
                ssnAvailable: maskSSN(this.ssn),
              });

              if (this.requestCatchup) {
                this.doRequestCatchup();
              }

              this.loading = false;
              this.close();
            },
            error => {
              this.loading = false;
              console.error(`Error creating link for ${this.member.id}`, error);
              this.error = error.error.message;

              if (error.error.message.includes('different state')) {
                this.showStateOverride = true;
              }
            }
          );
      }

      this.submitted = false;
    }
  }

  getStateOptIn() {
    switch (this.userAddressState) {
      case 'CA':
        return {
          stateName: 'CALIFORNIA',
          checkboxLabel: 'CaliforniaSaves Opt-In',
          inputLabel: 'Percentage To Withhold',
          inputType: 'percentage',
          inputDefault: 5,
        };
      case 'OR':
        return {
          stateName: 'OREGON',
          checkboxLabel: 'OregonSaves Opt-In',
          inputLabel: 'Percentage To Withhold',
          inputType: 'percentage',
          inputDefault: 5,
        };
      case 'NY':
        return {
          stateName: 'NEW YORK',
          checkboxLabel: 'FLI withholding Opt-In',
          inputLabel: '',
          inputType: 'none',
          inputDefault: 0,
        };
      case 'PA':
        return {
          stateName: 'PENNSYLVANIA',
          checkboxLabel: 'Local State Tax Opt-In',
          inputLabel: 'Local State Tax Amount',
          inputType: 'currency',
          inputDefault: 0,
        };
      default:
        return undefined;
    }
  }

  getStateOptInSelections() {
    const stateCode = this.member.address.state;

    let optInKey = 'RSPoptIn';
    let amountKey = 'RSPpercentage';

    if (stateCode === 'PA') {
      optInKey = 'LSToptIn';
      amountKey = 'LSTAmount';
    } else if (stateCode === 'NY') {
      optInKey = 'FLIoptIn';
    }

    return {
      stateConfig: {
        [stateCode.toLowerCase()]: {
          [optInKey]: this.stateOptIn,
          [amountKey]: this.stateOptInAmount,
        },
      },
    };
  }
}
