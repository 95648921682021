import { Component, DoCheck, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { Subscription } from 'rxjs';

import { formatSsn, toNumber } from '../../utils/string';
import { getIsDisabled, getIsHidden } from 'src/app/utils/ui';
import { BankService } from '../../services/bank.service';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';

export function checkboxRequired(control: AbstractControl): ValidationErrors | null {
  return control.value === true ? null : { required: true };
}

@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.css'],
})
export class DynamicFieldComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
  @Input() data: any;
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() parentHidden: boolean;
  private valueChangeSubscription: Subscription;
  mask = '';
  isHidden = false;
  isDisabled = false;
  invalid = false;
  control: FormControl;
  uuid = uuidv4();

  private previousValue: any;
  protected readonly getIsHidden = getIsHidden;

  constructor(public bankService: BankService) {}

  ngOnInit() {
    this.setupControl();
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  ngDoCheck() {
    if (this.formValueChanged()) {
      if (this.data.param === 'ssn' && this.form.value.ssn) {
        this.form.setValue({ ...this.form.value, ssn: formatSsn(this.form.value.ssn) });
      }

      const newIsHidden = this.getIsHidden(this.data.hidden) || this.parentHidden;

      if (newIsHidden !== this.isHidden) {
        this.isHidden = newIsHidden;
        this.updateValidators();
      }

      if (this.data.disabled) {
        const newIsDisabled = getIsDisabled(this.data.disabled);

        if (newIsDisabled !== this.isDisabled) {
          if (newIsDisabled) {
            this.form.get(this.data.param)?.disable();
          } else {
            this.form.get(this.data.param)?.enable();
          }
        }
      }

      this.previousValue = { ...this.form.value };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.parentHidden) {
      this.isHidden = this.getIsHidden(this.data.hidden) || this.parentHidden;
      this.updateValidators();
    }
  }

  setupControl() {
    const control = this.form.get(this.data.param);

    if (this.data.options?.format === 'phone') {
      this.mask = '(999) 999-9999';
    } else if (this.data.param === 'ssn') {
      this.mask = '999-99-9999';
    } else {
      this.mask = this.data.options?.format;
    }

    if (control) {
      this.updateValidators();

      // Subscribe to value changes if needed
      if (this.data.param === 'ssn') {
        this.valueChangeSubscription = control.valueChanges.subscribe(value => {
          if (value) {
            const formattedValue = formatSsn(value);
            if (formattedValue !== value) {
              control.setValue(formattedValue, { emitEvent: false });
            }
          }
        });
      }

      // Handle number inputs
      if ((this.data.type === 'hours' || this.data.type === 'number') && this.data.options?.format !== 'phone') {
        this.valueChangeSubscription = control.valueChanges.subscribe(value => {
          if (value != null) {
            const numericValue = toNumber(value);
            if (numericValue !== value) {
              control.setValue(numericValue || null, { emitEvent: false });
            }
          }
        });
      }

      // Handle bank routing number
      if (this.data.param === 'bankRouting') {
        this.valueChangeSubscription = control.valueChanges
          .pipe(skip(1), debounceTime(500), distinctUntilChanged())
          .subscribe(value => {
            if (value.length === 9) {
              this.bankService.validateRoutingNumber(value);
            }
          });
      }
    }
  }

  formValueChanged(): boolean {
    return JSON.stringify(this.previousValue) !== JSON.stringify(this.form.value);
  }

  updateValidators() {
    let control = this.form.get(this.data.param);

    if (!control) {
      control = new FormControl('');
      this.form.addControl(this.data.param, control);
    }

    if (control) {
      if (this.data.required && !this.isHidden) {
        if (this.data.type === 'toggle') {
          control.setValidators(checkboxRequired);
        } else {
          control.setValidators(Validators.required);
        }
      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity({ emitEvent: false });
    }
  }

  isInvalid() {
    return this.form.get(this.data.param.toString())?.invalid && this.submitted;
  }
}
