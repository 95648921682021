import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle-input',
  templateUrl: './toggle-input.component.html',
  styleUrls: ['./toggle-input.component.css'],
})
export class ToggleInputComponent implements OnInit {
  @Input() label: string;
  @Input() showInputDefault = false;
  showInput = false;

  ngOnInit() {
    this.showInput = this.showInputDefault;
  }

  toggleInput() {
    this.showInput = !this.showInput;
  }
}
