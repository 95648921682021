import { Component, OnInit } from '@angular/core';
import { objectToUrlParams } from '../utils/http';
import { AuthService } from '../services/auth.service';
import { TaxInfo, UserService } from '../services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-tax-info',
  templateUrl: './tax-info.component.html',
  styleUrls: ['./tax-info.component.css'],
})
export class TaxInfoComponent implements OnInit {
  loading = true;
  taxInfo: TaxInfo;
  authorizationDate: string;
  state = '';

  constructor(private auth: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    const taxInfoResponse = this.userService.getTaxInfos();

    taxInfoResponse.subscribe(
      res => {
        this.taxInfo = res.taxInfos?.length ? res.taxInfos[0] : undefined;
        this.loading = false;
      },
      err => {
        console.error('getTaxInfo error', err);
        this.loading = false;
      }
    );

    this.userService
      .getProfile()
      .pipe(take(1))
      .subscribe(
        ({
          profile: {
            authorizationDate,
            address: { state },
          },
        }) => {
          this.authorizationDate = authorizationDate;
          this.state = state;
        }
      );
  }

  openTaxInfoForm() {
    const formId = this.auth.user.uid;
    const params = objectToUrlParams({ formId, state: this.state });
    window.open(`/forms/taxNumbersForm?${params}`);
  }
}
