import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { ActivationService } from './activation.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivationGuard implements CanActivate {
  constructor(
    private router: Router,
    private act: ActivationService
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.act.getActivation().pipe(
        mergeMap(resp => {
          // console.log(resp);
          const isValid = resp.activation.activation_status === 'valid';
          if (!isValid) {
            this.router.navigate(['/activate'], {queryParams: {redirect: state.url}});
          }
          return of(isValid);
        })
      );
  }
}
