import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { capitalize } from '../utils/string';
import { UserService } from '../services/user.service';
import moment from 'moment-timezone';

const recaptchaContainerId = 'recaptcha-container';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('verificationCodeModal') verificationCodeModal: any;

  username: string;
  password: string;
  redirect?: string;
  user: any;
  isLoading = true;
  doingLogin = false;
  referral: string;
  windowRef: any;
  error: string;
  partnership: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private cookieService: CookieService,
    private userService: UserService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.redirect = params.redirect;
    });
    auth.userState.subscribe(user => {
      this.user = user;
      if (!this.doingLogin) {
        this.isLoading = false;
      }
    });
  }

  ngOnInit() {
    const cookieVal = this.cookieService.get('referral');
    this.referral = cookieVal !== '' ? cookieVal : null;
    this.partnership = this.referral != null ? `In partnership with ${capitalize(this.referral)}` : '';
    // Add recaptcha, which will be triggered by firebase 2FA
    this.windowRef = window;

    this.createRecaptchaContainer();

    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaContainerId, {
      size: 'invisible',
    });

    this.windowRef.recaptchaVerifier.render().then(widgetId => {
      this.windowRef.recaptchaWidgetId = widgetId;
    });
  }

  ngOnDestroy() {
    if (this.windowRef?.recaptchaVerifier && !this.windowRef?.recaptchaVerifier._delegate.destroyed) {
      this.windowRef.recaptchaVerifier.clear();
    }

    this.removeRecaptchaContainer();
  }

  private createRecaptchaContainer() {
    const container = document.createElement('div');
    container.id = recaptchaContainerId;
    document.body.appendChild(container);
  }

  private removeRecaptchaContainer() {
    const container = document.getElementById(recaptchaContainerId);
    if (container) {
      document.body.removeChild(container);
    }
  }

  async handleMFA(resolver: any) {
    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    // Send SMS verification code
    return phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, this.windowRef.recaptchaVerifier)
      .then(async verificationId => {
        try {
          const phone = resolver.hints[0].phoneNumber;

          let promptText = 'Please enter the 6 digit code sent to your phone';

          if (phone.length >= 4) {
            const phoneLastFour = phone.substring(phone.length - 4, phone.length);
            promptText = `Please enter the 6 digit code sent to your phone ending with ${phoneLastFour}`;
          }

          const code = prompt(promptText);

          if (!code) {
            this.isLoading = false;
            return;
          }

          const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
          const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

          const res = await resolver.resolveSignIn(multiFactorAssertion);
          this.isLoading = false;

          if (res.error) {
            this.error = res.error.message;
          } else {
            this.navigateToDestination();

            return res;
          }
        } catch (e) {
          this.isLoading = false;
          if (e.code === 'auth/invalid-verification-code') {
            this.error = 'The code you provided was not recognized. Please try again.';
          } else if (e.code === 'auth/too-many-requests') {
            this.error = e.message;
          } else {
            console.log('e', e);
            this.error = 'Something went wrong. Please try again.';
          }
        }
      })
      .catch(e => {
        if (e.code === 'auth/captcha-check-failed') {
          this.error = 'The reCAPTCHA verification could not be completed. Please try again.';
        } else if (e.code === 'auth/internal-error' || e.includes?.('reCAPTCHA')) {
          console.error(e);
          this.error = 'The reCAPTCHA verification could not be completed. Please reload the page and try again.';
        } else {
          this.error = 'Something went wrong. Please try again.';
        }
      });
  }

  async doLogin() {
    if (!this.username || !this.password) {
      alert('Please enter username and password');
      return;
    }

    try {
      this.doingLogin = true;
      this.isLoading = true;
      const user = await this.auth.doLogin(this.username, this.password);
      if (user) {
        console.log('logged in', user.uid.slice(-4));
      }

      this.userService.setTimezone(moment.tz.guess());

      this.isLoading = false;
      this.navigateToDestination();
    } catch (e) {
      if (e.code === 'auth/multi-factor-auth-required') {
        await this.handleMFA(e.resolver);
        return;
      } else if (e.code === 'auth/wrong-password') {
        this.error = 'The password was invalid or the user does not have a password';
      } else if (e.code === 'auth/user-not-found') {
        this.error = 'There is no user with this email address. Please try again.';
      } else if (e.code === 'auth/invalid-email') {
        this.error = 'Please enter a valid email address.';
      } else if (e.code === 'auth/wrong-password') {
        this.error = 'The username or password you entered is incorrect';
      } else if (e.code === 'auth/too-many-requests') {
        this.error =
          'Too many failed login attempts. You can immediately restore it by selecting Forgot Password, or you can try again later.';
      } else if (e.code === 'auth/network-request-failed') {
        this.error = 'We had trouble contacting the server. Please try again.';
      } else {
        console.log('Unhandled login code', e.code);
        this.error = 'An error occurred. Please try again later.';
      }
    } finally {
      this.isLoading = false;
    }
  }

  navigateToDestination() {
    if (this.redirect) {
      this.router.navigateByUrl(this.redirect);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  continueWithUser() {
    this.navigateToDestination();
  }

  changeUser() {
    this.auth.signOut();
    this.router.navigate(['/login']);
  }
}
