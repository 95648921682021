import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.css'],
})
export class FormInputComponent {
  @Input() title: string;
  @Input() submitted: boolean;
  @Input() controlName: string;
  @Input() inputType: string;
  @Input() group: FormGroup;
  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() mask: string;
  @Input() options: (string | number)[];

  isInvalid() {
    return this.group.get(this.controlName)?.invalid && this.submitted;
  }
}
