import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthService, Role } from './auth.service';
import { environment } from '../../environments/environment';
import { mergeMap, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment from 'moment/moment';
import { UserService } from './user.service';
import { Link, LinkService, PaymentInstrument } from './link.service';
import { getHeaders } from '../utils/http';
import { TeamService } from './team.service';

export interface YtdHours {
  bonus: number;
  doubletime: number;
  hours: number;
  incidentals: {
    healthCare: number;
    other: number;
  };
  incidentalsAmount: number;
  overtime: number;
  secondaryHours: number;
  sickBalance: number;
  sickHours: number;
  vacationBalance: number;
  vacationHours: number;
}

export interface Paystub {
  id: string;
  emailHistory: Record<
    string,
    {
      email: string;
      timestamp: string;
    }
  >;
  bonus: number;
  catchup?: boolean;
  config: {
    annualizedFederalIncomeWages: number;
    annualizedGross: number;
    annualizedStateIncomeWages: number;
    employerPayAWHH: true;
    gross: number;
    parameters: {
      annualizationFactor: number;
      employerCity: string;
      helperCity: string;
      helperCounty: string;
      helperState: string;
      state: string;
      w4: {
        allowancesChild: number;
        allowancesOther: number;
        federalAdditionalWithholding: number;
        federalExempt: false;
        ficaExempt: false;
        futaExempt: false;
        localExempt: false;
        stateAdditionalWithholding: number;
        stateAllowances: number;
        stateExempt: false;
        status: string;
        taxExempt: false;
      };
      yearToDate: {
        ytd: number;
        ytdWithholding: number;
      };
    };
    year: number;
  };
  canceledDate: string;
  createdDate: string;
  doubletime: number;
  employer: string;
  federalTax: {
    FIT: number;
    MDC: number;
    SS: number;
  };
  federalTaxYtd: {
    FIT: number;
    MDC: number;
    SS: number;
  };
  federalWithheld: number;
  gross: number;
  helper: string;
  hourlyRate: number;
  hours: number;
  incidentals: {
    healthCare: number;
    other: number;
  };
  incidentalsAmount: number;
  linkId: string;
  location: {
    employerCity: string;
    helperCity: string;
    helperCounty: string;
    helperState: string;
    state: string;
  };
  net: number;
  overtime: number;
  paid_date: string;
  payEnd: Date | string;
  payStart: Date | string;
  paymentInstrument: string;
  secondaryHours?: number;
  secondaryHourlyRate?: number;
  sickHours: number;
  stateTax: {
    PIT: number;
    SDI: number;
    FLI: number;
    CAF: number;
    RSP: number;
    LST: number;
    SUI: number;
    OPT: number;
    UI: number;
    'NY City Tax': number;
    'Yonkers City Tax': number;
    'Yonkers NR Tax': number;
  };
  stateTaxYtd: {
    PIT: number;
    SDI: number;
    FLI: number;
    CAF: number;
    RSP: number;
    LST: number;
    SUI: number;
    OPT: number;
    UI: number;
    'NY City Tax': number;
    'Yonkers City Tax': number;
    'Yonkers NR Tax': number;
  };
  stateWithheld: number;
  status: string;
  timezone: string;
  vacationHours: number;
  withholding: number;
  ytd: number;
  ytdHours: YtdHours;
  ytdWithholding: number;
}

export interface PaystubInput {
  linkId: string;
  wage: {
    hourlyRate: number;
    payEnd: Date | string;
    payStart: Date | string;
    hours: number;
    overtime?: number;
    doubletime?: number;
    bonus?: number;
    incidentalsAmount?: number;
    sickHours?: number;
    vacationHours?: number;
    secondaryHours?: number;
    secondaryHourlyRate: number;
    timesheetId?: string;
    ytdHours?: YtdHours;
    incidentals?: {
      healthCare: number;
      other: number;
    };
  };
  location: {
    state: string;
    employerCity: string;
    helperCity: string;
    helperCounty: string;
    helperState: string;
  };
}

export function paystubToPaystubInput(paystub: Paystub): PaystubInput {
  return {
    linkId: paystub.linkId,
    wage: {
      hourlyRate: paystub.hourlyRate,
      payEnd: new Date(paystub.payEnd),
      payStart: new Date(paystub.payStart),
      hours: paystub.hours,
      overtime: paystub.overtime,
      doubletime: paystub.doubletime,
      bonus: paystub.bonus,
      incidentalsAmount: paystub.incidentalsAmount,
      sickHours: paystub.sickHours,
      vacationHours: paystub.vacationHours,
      secondaryHours: paystub.secondaryHours,
      secondaryHourlyRate: paystub.secondaryHourlyRate,
      ytdHours: paystub.ytdHours,
      incidentals: paystub.incidentals,
    },
    location: {
      state: paystub.location.state,
      employerCity: paystub.location.employerCity,
      helperCity: paystub.location.helperCity,
      helperCounty: paystub.location.helperCounty,
      helperState: paystub.location.helperState,
    },
  };
}

export function paystubInputToPaystub(pi: PaystubInput, paystubToMerge?: Paystub): Paystub {
  return {
    ...paystubToMerge,
    hours: pi.wage.hours,
    payStart: pi.wage.payStart,
    payEnd: pi.wage.payEnd,
    overtime: pi.wage.overtime,
    doubletime: pi.wage.doubletime,
    bonus: pi.wage.bonus,
    incidentalsAmount: pi.wage.incidentalsAmount,
    secondaryHours: pi.wage.secondaryHours,
  };
}

@Injectable({
  providedIn: 'root',
})
export class PaystubService {
  private paystubPdfUrl = `https://${environment.firebase.server}/app/v2/paystub`;
  private paystubUrl = `https://${environment.firebase.server}/app/v2/paystub`;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private linkService: LinkService,
    private afDatabase: AngularFireDatabase,
    private http: HttpClient
  ) {}

  getPaystub(paystubId: string): Observable<Paystub> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.get<Paystub>(`${this.paystubUrl}/${paystubId}`, {
          headers: getHeaders(t),
        });
      })
    );
  }

  async getPaystubs(isEmployer: boolean, limit?: number): Promise<Paystub[]> {
    if (!this.auth.user) {
      return [];
    }

    const res = await this.afDatabase.database
      .ref('/paystubs')
      .orderByChild(isEmployer ? 'employer' : 'helper')
      .equalTo(this.auth.user.uid)
      .limitToFirst(limit ?? 9999)
      .once('value');

    const paystubs = res.val();

    if (!paystubs) {
      return [];
    }

    return Object.keys(paystubs)
      .map(key => ({
        id: key,
        ...paystubs[key],
      }))
      .sort((a, b) => (moment(b.paid_date).isBefore(moment(a.paid_date)) ? -1 : 1));
  }

  observePaystubs(isEmployer: boolean, limit?: number): Observable<Paystub[]> {
    return new Observable(observer => {
      if (!this.auth.user) {
        observer.next([]);
        return;
      }

      this.afDatabase.database
        .ref('/paystubs')
        .orderByChild(isEmployer ? 'employer' : 'helper')
        .equalTo(this.auth.user.uid)
        .limitToFirst(limit ?? 9999)
        .on(
          'value',
          snapshot => {
            const paystubs = snapshot.val();
            if (!paystubs) {
              observer.next([]);
              return;
            }

            const result = Object.keys(paystubs)
              .map(key => ({
                id: key,
                ...paystubs[key],
              }))
              .sort((a, b) => (moment(b.createdDate).isBefore(moment(a.createdDate)) ? -1 : 1));

            observer.next(result);
          },
          error => {
            observer.error(error);
          }
        );
    });
  }

  getPaystubPdfData(paystubId: string): Observable<any> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.get<Blob>(this.paystubPdfUrl, {
          headers: getHeaders(t),
          responseType: 'blob' as 'json',
          params: {
            paystubId,
          },
        });
      })
    );
  }

  async loadPaystubNames(paystubs: Paystub[], isEmployer: boolean) {
    if (!paystubs) {
      return;
    }

    const links = await this.linkService.getLink(this.auth.user.uid, isEmployer ? Role.employer : Role.helper);

    await Promise.all(
      paystubs.map(async paystub => {
        moment.locale(paystub.timezone || 'GMT');

        const link = links[paystub.linkId];

        const profileId = isEmployer ? link?.profiles.helper : link?.profiles.employer;

        if (this.teamService.loadedProfileIds.has(profileId)) {
          return;
        }

        this.teamService.loadedProfileIds.add(profileId);

        this.userService
          .getProfile(profileId)
          .pipe(take(1))
          .subscribe(({ profile: { firstName, lastName, address, phone, email, ssnAvailable, dateOfBirth } }) => {
            if (link?.profiles.employer && !this.teamService.getEmployerInfo(profileId)) {
              this.teamService.employers.push({
                id: link.profiles.employer,
                linkId: link.linkId,
                firstName,
                lastName,
                address,
                phone,
                email,
                link,
                ssnAvailable,
                dateOfBirth,
              });
            }

            if (link?.profiles.helper && !this.teamService.getHelperInfo(profileId)) {
              this.teamService.helpers.push({
                id: link.profiles.employer,
                linkId: link.linkId,
                firstName,
                lastName,
                address,
                phone,
                email,
                link,
                ssnAvailable,
                dateOfBirth,
              });
            }
          });
      })
    );
  }

  getPaystubName(paystub: Paystub, isEmployer: boolean) {
    if (isEmployer) {
      const profileId = this.userService.getLinkHelperProfileId(paystub.linkId);
      const helper = this.teamService.getHelperInfo(profileId);

      return helper ? `${helper.firstName} ${helper.lastName}` : 'Loading...';
    }

    const employer = this.teamService.getEmployerInfo(this.userService.getLinkEmployerProfileId(paystub.linkId));
    return employer ? `${employer.firstName} ${employer.lastName}` : 'Loading...';
  }

  getPaystubDisplayDate(paystub: Paystub) {
    const date = paystub.paid_date ?? paystub.canceledDate ?? paystub.createdDate;
    return moment(date).format('M/D');
  }

  createPaystub(paystub: PaystubInput) {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.post(this.paystubUrl, paystub, {
          headers: getHeaders(t),
          responseType: 'text',
        })
      )
    );
  }

  updatePaystub(paystubId: string, paystub: PaystubInput) {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.put<Paystub>(`${this.paystubUrl}/${paystubId}`, paystub, {
          headers: getHeaders(t),
        })
      )
    );
  }

  markPaystubPaid(paystubId: string, paymentInstrument: PaymentInstrument = 'unknown') {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.put(
          `${this.paystubUrl}/${paystubId}/markPaid`,
          {},
          {
            headers: getHeaders(t),
            params: {
              paymentInstrument,
            },
          }
        )
      )
    );
  }

  updateStatus(paystubId: string, status: string) {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.put(
          `${this.paystubUrl}/${paystubId}/status`,
          {},
          {
            headers: getHeaders(t),
            params: {
              status,
            },
          }
        )
      )
    );
  }

  deletePaystub(paystubId: string) {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.delete<any>(`${this.paystubUrl}/${paystubId}`, {
          headers: getHeaders(t),
        })
      )
    );
  }

  async getYtdHours(linkId: string, year: number) {
    const ytdHours = (await this.afDatabase.database.ref(`/ytd/${linkId}/`).once('value')).val();
    return ytdHours && ytdHours !== null ? ytdHours[`${year}`] : undefined;
  }
}
