import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
})
export class VerifyEmailComponent implements OnInit {
  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  code = '';
  error = '';
  message = 'Verifying email...';

  async verifyEmail() {
    this.error = '';

    try {
      await this.authService.doApplyActionCode(this.code);
      this.message = 'Your email has been verified!';
    } catch (error) {
      if (error.code === 'auth/invalid-action-code') {
        this.message = 'Your email address has already been verified.';
      } else {
        this.message = '';
        this.error = 'There was a problem verifying your email. Please try again.';
        console.error('doApplyActionCode error', error);
      }
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.code = params.code;

      void this.verifyEmail();
    });
  }
}
