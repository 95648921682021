import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivationService } from '../services/activation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css'],
})
export class ActivationComponent implements OnInit {
  activationCode: string;
  redirect?: string;
  referral: string;

  constructor(
    public auth: AuthService,
    private activatedRoute: ActivatedRoute,
    public act: ActivationService,
    private router: Router,
    private cookieService: CookieService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.redirect = params.redirect;
    });
  }

  ngOnInit() {
    const cookieVal = this.cookieService.get('referral');
    this.referral = cookieVal !== '' ? cookieVal : null;
    this.activationCode = null;
  }

  doActivation() {
    if (!this.activationCode) {
      alert('Please enter an activation code');
      return;
    }
    console.log('activate with code', this.activationCode);
    this.act.sendActivation(this.activationCode, this.referral).subscribe(resp => {
      if (this.redirect) {
        this.router.navigate([this.redirect]);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }
}
