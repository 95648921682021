import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-invoice-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class InvoiceItemComponent implements OnInit {
  @Input() invoice: Invoice;

  constructor() { }

  ngOnInit() {
  }
}
