import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getHeaders } from '../utils/http';

@Injectable({
  providedIn: 'root',
})
class InvoicesService {
  private invoicesUrl = `https://${environment.firebase.server}/app/v1/invoices`;

  constructor(private auth: AuthService, private http: HttpClient) {}

  getInvoices(): Observable<InvoiceResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => this.http.get<InvoiceResponse>(this.invoicesUrl, { headers: getHeaders(t) }))
    );
  }
}

class InvoiceResponse {
  status: string;
  invoices: Invoice[];
}

class Invoice {
  id: string;
  amount_due: number;
  date: number;
  invoice_pdf: string;
  hosted_invoice_url: string;
  total: number;
  status: string;
}

export { InvoicesService, InvoiceResponse, Invoice };
