import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-segment-control',
  templateUrl: './segment-control.component.html',
  styleUrls: ['./segment-control.component.css']
})
export class SegmentControlComponent {
  @Input() options: string[];
  @Input() selectedOption: string;
  @Output() optionSelected = new EventEmitter<string>();

  onOptionSelected(option: string) {
    this.selectedOption = option;
    this.optionSelected.emit(option);
  }
}