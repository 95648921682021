import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.css']
})
export class TitleHeaderComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() actionText: string;
  @Input() actionFunction: () => void;

  constructor() {}

  ngOnInit(): void {}

  onActionButtonClick() {
    if (this.actionFunction) {
      this.actionFunction();
    }
  }
}
