import { Paystub } from '../services/paystub.service';
import angularExpressions from 'angular-expressions';

export function getPaystubStatusBadgeClass(paystub: Paystub) {
  return {
    'bg-success': paystub.status === 'paid',
    'bg-warning text-dark': paystub.status === 'review',
    'bg-secondary': paystub.status === 'canceled',
  };
}

export function getPaystubRowClass(paystub: Paystub) {
  return {
    'payroll-approved': paystub.status === 'paid',
    'payroll-review': paystub.status === 'review',
    'payroll-canceled': paystub.status === 'canceled',
  };
}

export function getIsHidden(hiddenCondition: string): boolean {
  if (!hiddenCondition) {
    return false;
  }

  // TODO: Make sure replacing == NULL with == "" is the right thing to do for cases where the value is not a string
  const condition = hiddenCondition
    .replace(/\$(\w+)/g, 'this.form.value.$1')
    .replace(/== NULL/g, '== ""')
    .replace(/ == /g, ' === ');

  try {
    return angularExpressions.compile(condition)(this);
  } catch (e) {
    console.error('Error evaluating condition: ', e);
    return false;
  }
}

export function getIsDisabled(disabledCondition: string): boolean {
  if (!disabledCondition) {
    return false;
  }

  const condition = disabledCondition.replace(/\$(\w+)/g, 'this.form.value.$1');

  try {
    return angularExpressions.compile(condition)(this);
  } catch (e) {
    console.error('Error evaluating condition: ', e);
    return false;
  }
}
