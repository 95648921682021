import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

export interface Form {
  id: string;
  userId: string;
  name: string;
  fields: any[];
  createdAt: string;
  updatedAt: string;
}

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private afDatabase: AngularFireDatabase, private firestore: AngularFirestore) {}

  async getUserForms(userId: string) {
    const forms = await this.afDatabase.database.ref(`/forms`).orderByChild('userId').equalTo(userId).once('value');
    return forms.val();
  }

  observeForms(userId: string) {
    return new Observable<Record<string, Form>>(observer => {
      this.afDatabase.database
        .ref(`/forms`)
        .orderByChild('userId')
        .equalTo(userId)
        .on('value', snapshot => {
          const forms = snapshot.val();

          if (!forms) {
            observer.next();
            return;
          }

          observer.next(forms);
        });
    });
  }
}
