import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tax-chart',
  templateUrl: './tax-chart.component.html',
  styleUrls: ['./tax-chart.component.css'],
})
export class TaxChartComponent {
  @Input() netPay = 0;
  @Input() employeeTaxes = 0;
  @Input() yourTaxes = 0;

  get isLoading(): boolean {
    return this.netPay === 0 && this.employeeTaxes === 0 && this.yourTaxes === 0;
  }

  get netPayPercentage(): number {
    return this.getPercentage(this.netPay);
  }

  get employeeTaxesPercentage(): number {
    return this.getPercentage(this.employeeTaxes);
  }

  get yourTaxesPercentage(): number {
    return this.getPercentage(this.yourTaxes);
  }

  private getPercentage(value: number): number {
    const total = this.netPay + this.employeeTaxes + this.yourTaxes;
    return total > 0 ? (value / total) * 100 : 0;
  }
}
