import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { TeamService } from '../services/team.service';
import { take } from 'rxjs/operators';
import { StepListItem } from '../components/step-list/step-list.component';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css'],
})
export class OnboardingComponent implements OnInit {
  currentOnboardingStep = 1;
  onboardingComplete = false;
  isEmployer = false;
  profileId = '';
  employees = 0;

  constructor(public userService: UserService, private auth: AuthService, private teamService: TeamService) {}

  ngOnInit() {
    this.userService.isOnboardingComplete$.subscribe(isComplete => {
      this.onboardingComplete = isComplete;
    });

    this.userService.currentOnboardingStep$.subscribe(step => {
      this.currentOnboardingStep = step;
    });

    this.auth.role.subscribe(async role => {
      if (this.auth.user?.uid) {
        const newIsEmployer = role === 'employer';

        if (this.isEmployer !== newIsEmployer) {
          this.isEmployer = newIsEmployer;
          await this.teamService.getTeamMembers(newIsEmployer);
        }

        if (role === 'employer') {
          this.teamService.helpers$.subscribe(helpers => {
            this.employees = helpers.filter(t => t.link.status === 'active').length;
          });
        }
      }
    });

    this.userService
      .getConfig()
      .pipe(take(1))
      .subscribe(configRes => {
        this.profileId = configRes?.config?.profileId;
      });
  }

  stepToStatus(step: number): 'incomplete' | 'current' | 'done' {
    return this.currentOnboardingStep > step ? 'done' : this.currentOnboardingStep === step ? 'current' : 'incomplete';
  }

  getItems(): StepListItem[] {
    return [
      { id: 1, title: 'Complete Profile', status: this.stepToStatus(1) },
      { id: 2, title: 'Enter Checking Account for Taxes', status: this.stepToStatus(2) },
      { id: 3, title: 'Sign Authorization', status: this.stepToStatus(3) },
    ];
  }

  continue() {
    window.location.href = '/forms/onboardingWizard?formId=-NwGlzkxz2mUXGyeIaFM';
  }
}
