import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  username: string;
  isLoading = true;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.isLoading = false;
  }

  async doForgot() {
    this.isLoading = true;
    const url = 'https://' + environment.firebase.server + this.router.createUrlTree(['dashboard']);

    const actionCodeSettings = {
      url,
      handleCodeInApp: false
    };

    await this.auth.doForgot(this.username, actionCodeSettings);
    alert('Please check your email for instructions on how to recover your password.');
    this.router.navigate(['/login']);
  }
}
