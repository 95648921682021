import { Component } from '@angular/core';
import { DashboardService } from '../../dashboard/dashboard.service';

@Component({
  selector: 'app-toolkit',
  templateUrl: './toolkit.component.html',
  styleUrls: ['./toolkit.component.css'],
})
export class ToolkitComponent {
  constructor(private dashboardService: DashboardService) {}

  onClose() {
    this.dashboardService.setFeature('');
  }
}
