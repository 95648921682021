import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor() {}

  getPlatform(): 'web' | 'ios' | 'android' {
    const userAgent = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return 'ios';
    }

    if (/Android/i.test(userAgent)) {
      return 'android';
    }

    return 'web';
  }

  isMobile(): boolean {
    return this.getPlatform() !== 'web';
  }
}
