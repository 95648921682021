import { Component, Input } from '@angular/core';
import { TeamMember } from '../../services/team.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent {
  @Input() inactive: boolean;
  @Input() medium: boolean;
  @Input() small: boolean;
  @Input() member?: TeamMember;
  @Input() loading: boolean;
}
