import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-link-to',
  templateUrl: './link-to.component.html',
  styleUrls: ['./link-to.component.css']
})
export class LinkToComponent {
  redirect?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.redirect = params.url || params.redirect;

      // Set or delete the referral cookie based on the query parameter
      if (params['referral']) {
        this.cookieService.set('referral', params['referral']);
      } else {
        this.cookieService.delete('referral');
      }

      // Delay the redirection by 0.4 second
      if (this.redirect) {
        setTimeout(() => {
          window.location.href = this.redirect!;
        }, 400); // 1000 ms = 1 second
      }
    });
    
  }

}
