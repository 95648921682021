import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-revert-second-factor',
  templateUrl: './revert-second-factor.component.html',
  styleUrls: ['./revert-second-factor.component.css'],
})
export class RevertSecondFactorComponent implements OnInit {
  @Input() code: string;

  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  error = '';
  message = '';
  loading = false;

  async revertSecondFactor() {
    this.loading = true;
    this.error = '';

    try {
      const info = await this.authService.doCheckActionCode(this.code);

      const multiFactorInfo = info['data']['multiFactorInfo'];
      const obfuscatedPhoneNumber = multiFactorInfo['phoneNumber'];

      await this.authService.doApplyActionCode(this.code);
      this.message = `The phone number ${obfuscatedPhoneNumber} has been removed as a second factor from your account. You may also want to reset your password if you suspect your account was compromised.`;

      this.loading = false;
    } catch (error) {
      this.message = '';
      this.error = '2 step verification could not be removed. The link may have expired or may have already been used.';
      console.error('doApplyActionCode error', error);

      this.loading = false;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.code = params.code;
    });
  }
}
