import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { maskAccountNumber, onKeyDownNumbersOnly } from '../utils/input';
import { TaxInfo, UserService } from '../services/user.service';
import { NgForm, NgModel } from '@angular/forms';
import { BankService } from '../services/bank.service';
import { objectToUrlParams } from '../utils/http';
import { AuthService } from '../services/auth.service';
import { ModalService } from '../services/modal.service';

class FormFields {
  constructor(public routingNumber = '', public accountNumber = '', public accountNumberRepeat = '') {}
}

@Component({
  selector: 'app-bank-account-details',
  templateUrl: './bank-account-details.component.html',
  styleUrls: ['./bank-account-details.component.css'],
})
export class BankAccountDetailsComponent implements OnInit, AfterViewInit {
  loadingTaxInfo = true;
  loadingConfig = true;
  saving = false;
  submitted = false;
  error = '';
  existingTaxInfo: TaxInfo;
  editing = false;
  locked = false;
  onKeyDownNumbersOnly = onKeyDownNumbersOnly;
  model = new FormFields();

  @Input() isLinkRequest: boolean;
  @Input() onSave: (accountNumber: string, routingNumber: string) => void;
  @ViewChild('routingNumber') routingInput: NgModel;
  @ViewChild(NgForm) form!: NgForm;

  constructor(
    private userService: UserService,
    public bankService: BankService,
    private auth: AuthService,
    private modal: ModalService
  ) {}

  setAccountFromTaxInfo() {
    this.model.accountNumber = maskAccountNumber(this.existingTaxInfo.bankAccount);
    this.model.routingNumber = this.existingTaxInfo.bankRouting;
  }

  edit() {
    this.editing = true;
    this.error = '';

    // Clear account number inputs when editing so users don't submit masked account numbers. Also prevents mismatch error if
    // user edits only one of the two account boxes (because it would compare the masked to unmasked).
    this.model.accountNumber = '';
    this.model.accountNumberRepeat = '';
  }

  cancelEdit() {
    this.editing = false;
    this.error = '';
    this.setAccountFromTaxInfo();
  }

  editLocked() {
    const formId = this.auth.user.uid;
    const params = objectToUrlParams({ formId });
    this.modal.openInNewTab(`/forms/updateBankForm?${params}`);
  }

  ngOnInit(): void {
    const taxInfoResponse = this.userService.getTaxInfos();

    taxInfoResponse.subscribe(
      res => {
        this.existingTaxInfo = res.taxInfos?.length ? res.taxInfos[0] : undefined;

        if (this.existingTaxInfo?.bankAccount) {
          this.setAccountFromTaxInfo();
        }

        this.loadingTaxInfo = false;
      },
      err => {
        console.error('getTaxInfo error', err);
        this.loadingTaxInfo = false;
      }
    );

    this.userService.getConfig().subscribe(
      configRes => {
        this.locked = !!configRes?.config?.lock?.bankAccount;
        this.loadingConfig = false;
      },
      err => {
        console.error('getConfig error', err);
        this.loadingConfig = false;
      }
    );
  }

  ngAfterViewInit() {
    // Debounce routing number input
    this.routingInput.control.valueChanges.pipe(skip(1), debounceTime(500), distinctUntilChanged()).subscribe(value => {
      this.model.routingNumber = value;

      if (value.length === 9) {
        this.bankService.validateRoutingNumber(value);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.error = '';

    if (
      this.form.valid &&
      this.model.accountNumber === this.model.accountNumberRepeat &&
      this.bankService.bankName !== this.bankService.unknownRoutingNumber
    ) {
      if (this.model.accountNumber.includes('*')) {
        this.error = 'Please enter your full account number without the * characters.';
        return;
      }

      if (this.isLinkRequest && this.onSave) {
        this.onSave?.(this.model.accountNumber, this.model.routingNumber);
      } else {
        this.saving = true;
        this.userService
          .updateTaxInfo({
            id: this.existingTaxInfo?.id,
            bankAccount: this.model.accountNumber,
            bankRouting: this.model.routingNumber,
          })
          .subscribe(
            res => {
              this.model.accountNumber = maskAccountNumber(this.model.accountNumber);
              this.saving = false;
              this.editing = false;
              this.submitted = false;
              this.existingTaxInfo = res.taxInfos?.length ? res.taxInfos[0] : undefined;
            },
            err => {
              console.error('updateTaxInfo error', err);
              this.error = 'Sorry, we were unable to save your bank account information. Please try again.';
              this.saving = false;
            }
          );
      }
    }
  }
}
