import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TeamService } from '../../services/team.service';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  constructor(public auth: AuthService, private teamService: TeamService) {}

  signOut() {
    this.teamService.clearTeamMembers();
    void this.auth.signOut();
  }
}
