import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

class FormFields {
  constructor(public password: string, public passwordRepeat: string) {}
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  @Input() code: string;

  model = new FormFields('', '');

  @ViewChild(NgForm) form!: NgForm;

  submitted = false;
  success = false;
  error: string;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  async onSubmit() {
    this.submitted = true;
    this.error = '';

    if (this.form.valid && this.model.password === this.model.passwordRepeat) {
      try {
        await this.authService.doUpdatePassword(this.code, this.model.password);
        this.success = true;
      } catch (error) {
        console.error(error);
        if (error.code === 'auth/invalid-action-code') {
          this.error = 'This password reset request may have expired or has already been used.';
        } else {
          this.error = 'An unknown error occurred. Please try resetting your password again.';
        }
      }
    }
  }

  continueToLogin() {
    void this.router.navigateByUrl('/');
  }
}
