import { Component, Input } from '@angular/core';

export interface StepListItem {
  id: number;
  title: string;
  status: 'incomplete' | 'current' | 'done';
}

@Component({
  selector: 'app-step-list',
  templateUrl: './step-list.component.html',
  styleUrls: ['./step-list.component.css'],
})
export class StepListComponent {
  @Input() items: StepListItem[];
}
