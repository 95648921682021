import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupComponent } from '../signup/signup.component';
import { SubscribeComponent } from '../subscribe/subscribe.component';
import { ActivationComponent } from '../activation/activation.component';
import { AuthGuard } from '../services/auth.guard';
import { ActivationGuard } from '../services/activation.guard';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { SubscriptionGuard } from '../services/subscription.guard';
import { LoginComponent } from '../login/login.component';
import { InvoicesComponent } from '../dashboard/invoices/invoices.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ReferralManagerComponent } from '../referral-manager/referral-manager.component';
import { LinkRequestComponent } from '../link-request/link-request.component';
import { AuthActionComponent } from '../auth-action/auth-action.component';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { RevertSecondFactorComponent } from '../revert-second-factor/revert-second-factor.component';
import { BankAccountDetailsComponent } from '../bank-account-details/bank-account-details.component';
import { ProfileComponent } from '../profile/profile.component';
import { TestPageComponent } from '../components/page/tests/test-page/test-page.component';
import { DynamicFormsIndexComponent } from '../components/page/tests/dynamic-forms-index/dynamic-forms-index.component';
import { DashboardOldComponent } from '../dashboard-old/dashboard.component';
import { LinkToComponent } from '../link-to/link-to.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotPasswordComponent },
  { path: 'activate', component: ActivationComponent, canActivate: [AuthGuard] },
  { path: 'subscribe', component: SubscribeComponent, canActivate: [AuthGuard, ActivationGuard] },
  {
    path: 'dashboardOld',
    component: DashboardOldComponent,
    canActivate: [AuthGuard, ActivationGuard, SubscriptionGuard],
  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, ActivationGuard, SubscriptionGuard] },
  {
    path: 'dashboard/invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard, ActivationGuard, SubscriptionGuard],
  },
  { path: 'linkTo', component: LinkToComponent },
  { path: 'referrals', component: ReferralManagerComponent, canActivate: [AuthGuard, ActivationGuard] },
  { path: 'auth/action', component: AuthActionComponent },
  { path: 'linkRequest/:id', component: LinkRequestComponent, canActivate: [AuthGuard] },
  { path: 'verifyEmail', component: VerifyEmailComponent, canActivate: [AuthGuard] },
  { path: 'revertSecondFactor', component: RevertSecondFactorComponent, canActivate: [AuthGuard] },
  { path: 'bankAccount', component: BankAccountDetailsComponent, canActivate: [AuthGuard] },
  { path: 'profileEdit', component: ProfileComponent, outlet: 'profile', canActivate: [AuthGuard] },
  { path: 'profileBank', component: BankAccountDetailsComponent, outlet: 'profile', canActivate: [AuthGuard] },
  { path: 'forms', component: DynamicFormsIndexComponent },
  { path: 'forms/:id', component: TestPageComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
