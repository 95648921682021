import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard-header-old',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderOldComponent implements OnInit {
  constructor(public auth: AuthService) {}

  ngOnInit() {}
}
