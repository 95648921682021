import { Pipe, PipeTransform } from '@angular/core';
import { stringToHTML } from '../utils/string';

@Pipe({
  name: 'stringToHtml',
})
export class StringToHtmlPipe implements PipeTransform {
  transform(value: string): string {
    return stringToHTML(value);
  }
}
