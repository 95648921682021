import { Component, OnInit } from '@angular/core';
import { InvoicesService, Invoice } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {

  invoices: Invoice[];
  isLoading = true;

  constructor(private invoiceManager: InvoicesService) { }

  ngOnInit() {
    this.invoiceManager.getInvoices().subscribe(resp => {
      this.invoices = resp.invoices;
      this.isLoading = false;
    });
  }

}
