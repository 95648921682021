import { HttpHeaders } from '@angular/common/http';
import { formatPhoneNumber } from './string';

export function getHeaders(token: string) {
  const headers = new HttpHeaders();
  return headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization', `Bearer ${token}`);
}

export function objectToUrlParams(obj: any, prefix?: string): string {
  const queryString = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const paramKey = prefix ? `${encodeURIComponent(key)}_${prefix}` : encodeURIComponent(key);

      if (value !== null && typeof value === 'object') {
        // Recursively process nested objects with reversed key order
        queryString.push(objectToUrlParams(value, paramKey));
      } else {
        queryString.push(`${paramKey}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryString.join('&');
}

export function urlParamsToObject(queryString: string): any {
  const obj: any = {};

  // Remove the leading '?' if present
  const params = queryString.replace(/^\?/, '').split('&');

  params.forEach(param => {
    let [key, value] = param.split('=');
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);

    // Parse the key to extract nested keys using underscores
    const keyParts = key.split('_');

    // Build the nested object
    let current = obj;
    for (let i = 0; i < keyParts.length; i++) {
      const part = keyParts[i];
      if (i === keyParts.length - 1) {
        // Assign the value at the deepest level
        current[part] = parseValue(key, value);
      } else {
        // Ensure the current level is an object
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    }
  });

  return obj;
}

export function injectFormId(params: object, formId: string) {
  if (!params) {
    return {
      formId,
    };
  }
  return Object.keys(params).includes('formId')
    ? params
    : {
        ...params,
        formId,
      };
}

function isPhoneNumber(value: string): boolean {
  const formattedPhoneRegex = /^\(\d{3}\)\s?\d{3}-\d{4}$/;
  return formattedPhoneRegex.test(value);
}

// Parse URL param values to appropriate types
function parseValue(key: string, value: string, isNumber?: boolean): any {
  if (key.toLowerCase().includes('phone') || isPhoneNumber(value)) return formatPhoneNumber(value);

  if (value === 'true') return true;
  if (value === 'false') return false;
  if (isNumber) return Number(value);
  return value;
}

// Check if a string is a number. Returns false if it's a date string.
function isNumeric(value: string): boolean {
  // Regular expression to test if the entire string is a number
  return /^-?\d+(\.\d+)?$/.test(value);
}

export function flatObjectToNested(obj: { [key: string]: any }, numberFieldKeys?: string[]): any {
  const result: any = {};

  const keyEndingWithNumberRegex = /^(.+?)(\d+)$/;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = parseValue(key, obj[key], numberFieldKeys?.includes(key)); // Parse the value to appropriate type

      if (key.startsWith('withWithholdingOk') || key.startsWith('detailsOk')) {
        result[key] = value;
      } else if (key.includes('_')) {
        const keyParts = key.split('_').reverse(); // Reverse to restore original nesting order
        let current = result;
        for (let i = 0; i < keyParts.length; i++) {
          const part = keyParts[i];
          if (i === keyParts.length - 1) {
            current[part] = value;
          } else {
            if (!current[part]) {
              current[part] = {};
            }
            current = current[part];
          }
        }
      } else if (keyEndingWithNumberRegex.test(key)) {
        const match = key.match(keyEndingWithNumberRegex);
        if (match) {
          const baseKey = match[1];
          const numberKey = match[2];

          if (!result[numberKey]) {
            result[numberKey] = {};
          }
          result[numberKey][baseKey] = value;
        }
      } else {
        result[key] = value;
      }
    }
  }

  return result;
}
