import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { BehaviorSubject, Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { environment } from '../../environments/environment';
import { getHeaders } from '../utils/http';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userState: Observable<any>;
  public user: firebase.User;
  public idToken: Observable<string>;
  public role = new BehaviorSubject<Role>(null);

  constructor(private afAuth: AngularFireAuth, private http: HttpClient, private router: Router) {
    firebase.initializeApp(environment.firebase);
    this.userState = this.afAuth.user;
    this.userState.subscribe(u => {
      this.user = u;
    });
    this.idToken = this.afAuth.idToken;

    this.afAuth.idTokenResult.subscribe(result => {
      if (result && result.claims) {
        this.role.next(result.claims.role as Role);
      } else {
        this.role.next(null);
      }
    });
  }

  isLoggedIn(): boolean {
    let loggedIn = false;
    this.idToken.subscribe(idToken => {
      loggedIn = !!idToken;
    });
    return loggedIn;
  }

  async doLogin(username: string, pass: string) {
    try {
      const cred = await this.afAuth.signInWithEmailAndPassword(username, pass);
      return cred.user;
    } catch (e) {
      throw e;
    }
  }

  async doForgot(username: string, actionCodeSettings) {
    try {
      await this.afAuth.sendPasswordResetEmail(username, actionCodeSettings);
    } catch (e) {
      throw e;
    }
  }

  async doSignup(username: string, pass: string) {
    try {
      const cred = await this.afAuth.createUserWithEmailAndPassword(username, pass);
      return cred.user;
    } catch (e) {
      throw e;
    }
  }

  async doLoginWithLink(email: string, url: string) {
    try {
      if (await this.afAuth.isSignInWithEmailLink(url)) {
        const cred = await this.afAuth.signInWithEmailLink(email, url);
        return cred.user;
      }
    } catch (e) {
      throw e;
    }
  }

  async doCheckActionCode(code: string) {
    try {
      return await this.afAuth.checkActionCode(code);
    } catch (e) {
      throw e;
    }
  }

  async doApplyActionCode(code: string) {
    try {
      return await this.afAuth.applyActionCode(code);
    } catch (e) {
      throw e;
    }
  }

  doSetPassword(password: string): Observable<any> {
    return this.idToken.pipe(
      mergeMap(t => {
        return this.http.post(
          `https://${environment.firebase.server}/app/v1/password`,
          { password },
          {
            headers: getHeaders(t),
          }
        );
      })
    );
  }

  async doUpdatePassword(code: string, newPassword: string) {
    try {
      await this.afAuth.verifyPasswordResetCode(code);
      await this.afAuth.confirmPasswordReset(code, newPassword);
    } catch (e) {
      throw e;
    }
  }

  async signOut() {
    sessionStorage.clear();
    await this.afAuth.signOut();
    return this.router.navigate(['/']);
  }

  isSignInWithEmailLink(email: string) {
    return this.afAuth.isSignInWithEmailLink(email);
  }

  fetchSignInMethodsForEmail(email: string) {
    return this.afAuth.fetchSignInMethodsForEmail(email);
  }
}

export enum Role {
  helper = 'helper',
  employer = 'employer',
}
