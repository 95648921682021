import { Pipe, PipeTransform } from '@angular/core';
import { ValidationStatus } from '../services/user.service';
/*
 * Convert a user validation status to our preferred display text
 */
@Pipe({ name: 'validationStatus' })
export class ValidationStatusPipe implements PipeTransform {
  transform(status: ValidationStatus): string {
    if (['unverified', 'retry', 'kba', 'document', 'unknown'].includes(status)) {
      return 'UNVERIFIED';
    }

    return status?.toUpperCase() ?? 'UNVERIFIED';
  }
}
