// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'dev',
  firebase: {
    server: 'dev.nestpayroll.com',
    apiKey: 'AIzaSyClSaqEFV5lJTfj9AJ0Z9h4yhPwzGJVByg',
    authDomain: 'nestpayroll-dev.firebaseapp.com',
    databaseURL: 'https://nestpayroll-dev.firebaseio.com',
    projectId: 'nestpayroll-dev',
    storageBucket: 'nestpayroll-dev.appspot.com',
    messagingSenderId: '325525892046',
  },
  stripe: {
    key: 'pk_test_Q49kDYlboOzIDypHdUcPWQx9',
  },
  google: {
    placesKey: 'AIzaSyBEpom28_4LIAUUmfln5QF9EIVadrCFf60',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
