import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';

class FormFields {
  constructor(public email: string) {}
}

@Component({
  selector: 'app-link-sign-in',
  templateUrl: './link-sign-in.component.html',
  styleUrls: ['./link-sign-in.component.css'],
})
export class LinkSignInComponent {
  @Input() continueUrl: string;

  model = new FormFields('');

  @ViewChild(NgForm) form!: NgForm;

  loading = false;
  submitted = false;
  success = false;
  error: string;
  expiredError = false;

  constructor(private authService: AuthService) {}

  async onSubmit() {
    this.expiredError = false;
    this.submitted = true;
    this.error = '';

    if (this.form.valid) {
      this.loading = true;

      try {
        await this.authService.doLoginWithLink(this.model.email.trim(), window.location.href);
        this.navigateToDestination();
      } catch (error) {
        console.error(error);
        if (error.code === 'auth/invalid-email') {
          this.error =
            'This email does not match the address where the invitation was sent. Please check the email and try again.';
        } else if (error.code === 'auth/expired-action-code' || error.code === 'auth/invalid-action-code') {
          this.expiredError = true;
          this.error =
            'This invite may have expired or has already been used. Please contact the employer and ask them to invite you again.';
        } else if (error.code === 'auth/multi-factor-auth-required') {
          console.error(error);
          this.error =
            'This account is already in use and requires 2-Step Verification. Please ask the employer to send the request to an email that is not already in use.';
        } else {
          this.error =
            'Oops! Something went wrong. If the problem persists, contact us any time at hello@nestpayroll.com.';
        }
      } finally {
        this.loading = false;
      }

      this.loading = false;
    }
  }

  navigateToDestination() {
    if (this.continueUrl) {
      document.location.href = this.continueUrl;
    } else {
      this.error = 'There was a problem with the invite. Please contact the employer and ask them to invite you again.';
      console.error('No continueUrl provided');
    }
  }
}
