import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Profile, UserService } from '../../../../services/user.service';
import { Paystub, PaystubService } from '../../../../services/paystub.service';
import { AuthService, Role } from '../../../../services/auth.service';

@Component({
  selector: 'app-dynamic-forms-index',
  templateUrl: './dynamic-forms-index.component.html',
  styleUrls: ['./dynamic-forms-index.component.css'],
})
export class DynamicFormsIndexComponent implements OnInit {
  profileId: string;
  profile: Profile;
  paystubs: Paystub[] = [];
  isEmployer = true;

  constructor(private userService: UserService, private paystubService: PaystubService, private auth: AuthService) {}

  ngOnInit(): void {
    this.userService
      .getConfig()
      .pipe(take(1))
      .subscribe(
        configRes => {
          this.profileId = configRes?.config?.profileId;

          this.userService
            .getProfile(this.profileId)
            .pipe(take(1))
            .subscribe(
              res => {
                this.profile = res.profile;
              },
              () => {}
            );
        },
        () => {}
      );

    this.auth.role.subscribe(role => {
      this.isEmployer = role === Role.employer;

      this.paystubService.getPaystubs(this.isEmployer).then(
        paystubs => {
          this.paystubs = paystubs;
        },
        () => {}
      );
    });
  }

  getCatchupCombinedUrl() {
    const startYear = 2022;
    const endYear = 2023;
    const linkId = this.paystubs[0]?.linkId;

    return `/forms/catchupCombinedForm?startYear=${startYear}&endYear=${endYear}&linkId=${linkId}&firstName=${this.profile?.firstName}`;
  }
}
