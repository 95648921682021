import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { getHeaders } from '../utils/http';

@Injectable({
  providedIn: 'root',
})
class ReferralService {
  private referralsUrl = `https://${environment.firebase.server}/app/v1/referrals`;

  constructor(private auth: AuthService, private http: HttpClient) {}

  getReferrals(): Observable<ReferralResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => this.http.get<ReferralResponse>(this.referralsUrl, { headers: getHeaders(t) }))
    );
  }
}

class ReferralResponse {
  status: string;
  role: any;
  referrals: Referral[];
}

class Referral {
  id: string;
  amount_due: number;
  date: number;
  invoice_pdf: string;
  hosted_invoice_url: string;
  total: number;
  status: string;
}

export { ReferralService, ReferralResponse, Referral };
