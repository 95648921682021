import { Component, Input, OnInit } from '@angular/core';
import { Paystub, PaystubService } from '../../services/paystub.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ModalService } from '../../services/modal.service';
import { formatCurrency } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlatformService } from '../../services/platform.service';
import { PaymentInstrument } from '../../services/link.service';
import { take } from 'rxjs/operators';
import moment from 'moment';
import { TeamMember } from '../../services/team.service';

type Pages = 'main' | 'venmo' | 'cashapp';

@Component({
  selector: 'app-paystub-pay',
  templateUrl: './paystub-pay.component.html',
  styleUrls: ['./paystub-pay.component.css'],
})
export class PaystubPayComponent implements OnInit {
  @Input() paystub: Paystub;
  @Input() paymentInstrument: PaymentInstrument;
  @Input() member: TeamMember;
  page: Pages = 'main';
  note = '';
  venmoId = '';
  cashtag = '';
  showPreferredPaymentMethod = false;
  venmoSelectContact = false;

  constructor(
    private clipboard: Clipboard,
    private modalService: NgbModal,
    private modal: ModalService,
    public platformService: PlatformService,
    private paystubService: PaystubService
  ) {}

  ngOnInit(): void {
    this.note = `Paycheck for ${moment(this.paystub.payStart).format('MMM D')} - ${moment(this.paystub.payEnd).format(
      'MMM D, YYYY'
    )}`;

    this.showPreferredPaymentMethod =
      this.paymentInstrument === 'venmo' || this.paymentInstrument === 'cashapp' || this.paymentInstrument === 'other';

    // Leaving the username blank only works for mobile deep links, not http links
    this.venmoSelectContact = this.platformService.isMobile();
  }

  markAsPaid(paymentInstrument: PaymentInstrument) {
    this.paystubService
      .markPaystubPaid(this.paystub.id, paymentInstrument)
      .pipe(take(1))
      .subscribe(() => {
        console.log(`Marked paystub as paid with "${paymentInstrument}"`);
      });
  }

  onCopyClick() {
    this.modalService.dismissAll();

    this.clipboard.copy(this.paystub.net.toString());
    this.modal.showMessage(
      'Copied',
      `Copied net pay amount (${formatCurrency(this.paystub.net, 'en-us', '$')}) to the clipboard.`
    );

    this.markAsPaid('other');
  }

  getVenmoWebUrl(username: string, amount: number, note: string) {
    return `https://venmo.com/${username}?txn=pay&amount=${amount}&note=${note}`;
  }

  getVenmoMobileUrl(username: string, amount: number, note: string) {
    return `venmo://paycharge?txn=pay&amount=${amount}&note=${note}${
      this.venmoSelectContact ? '' : `&recipients=${username}`
    }`;
  }

  getCashAppWebUrl(username: string, amount: number) {
    return `https://cash.app/$${username}?amount=${amount}`;
  }

  getCashAppMobileUrl(username: string, amount: number) {
    return `cashapp://cash.me/${username}?amount=${amount}`;
  }

  openVenmoUrl() {
    let url = '';

    if (this.platformService.isMobile()) {
      url = this.getVenmoMobileUrl(this.venmoId, this.paystub.net, this.note);
    } else {
      url = this.getVenmoWebUrl(this.venmoId, this.paystub.net, this.note);
    }

    window.open(url, '_blank');
  }

  openCashAppUrl() {
    let url = '';

    if (this.platformService.isMobile()) {
      url = this.getCashAppMobileUrl(this.cashtag, this.paystub.net);
    } else {
      url = this.getCashAppWebUrl(this.cashtag, this.paystub.net);
    }

    window.open(url, '_blank');
  }

  onVenmoClick() {
    this.page = 'venmo';
  }

  onBackClick() {
    this.page = 'main';
  }

  onVenmoNavigateClick() {
    if (!this.venmoSelectContact && !this.venmoId) {
      this.modal.showMessage('Error', 'Please enter a Venmo username.');
      return;
    }

    this.openVenmoUrl();
    this.markAsPaid('venmo');
    this.modalService.dismissAll();
  }

  onCashAppClick() {
    this.page = 'cashapp';
  }

  onCashAppNavigateClick() {
    if (!this.cashtag) {
      this.modal.showMessage('Error', 'Please enter a $Cashtag.');
      return;
    }

    this.openCashAppUrl();
    this.markAsPaid('cashapp');
    this.modalService.dismissAll();
  }

  onShowMoreOptionsClick() {
    this.showPreferredPaymentMethod = false;
  }

  close() {
    this.modalService.dismissAll();
  }
}
