import { Component } from '@angular/core';
import { DashboardService } from '../../dashboard/dashboard.service';

@Component({
  selector: 'app-toolkit-card',
  templateUrl: './toolkit-card.component.html',
  styleUrls: ['./toolkit-card.component.css'],
})
export class ToolkitCardComponent {
  constructor(private dashboardService: DashboardService) {}

  openToolkit() {
    this.dashboardService.setFeature('toolkit');
  }
}
