import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  private API_KEY = 'AIzaSyDbVacsIcNLZGXlvGGOfbXw_eWotqiujUE';
  private API_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${this.API_KEY}&address=`;

  constructor(private http: HttpClient) {}

  getLatLong(address: string): Observable<any> {
    const url = `${this.API_URL}${address}`;
    return this.http.get(url).pipe(map(response => response['results'][0]['geometry']['location']));
  }
}
