import { Directive, forwardRef, HostBinding, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appButtonGroup]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonGroupDirective),
      multi: true,
    },
  ],
})
export class ButtonGroupDirective implements ControlValueAccessor {
  @Input() appButtonGroup: string[] = [];
  private onChange: (value: string) => void;
  private onTouched: () => void;
  private value: string;

  @HostBinding('class.active') isActive = false;

  @HostListener('click', ['$event.target'])
  handleClick(button: HTMLElement) {
    this.value = button.id.trim();
    this.isActive = true;
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle disabled state
  }
}
