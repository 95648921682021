import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, Role } from '../services/auth.service';
import { Router } from '@angular/router';
import { UserService, ValidationStatus } from '../services/user.service';
import { take } from 'rxjs/operators';
import { TeamService } from '../services/team.service';

export type Features = 'profileEdit' | 'bankInfo' | 'taxInfo' | 'subscription';

@Component({
  selector: 'app-paystub-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css'],
})
export class ProfileDialogComponent implements OnInit {
  feature: Features = 'profileEdit';
  firstName = '';
  lastName = '';
  email = '';
  validationStatus: ValidationStatus;
  showDetails;
  isEmployer: boolean;

  constructor(
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    public auth: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    public router: Router
  ) {}

  getValidationClass() {
    switch (this.validationStatus) {
      case 'unverified':
      case 'retry':
      case 'kba':
      case 'document':
      case 'unknown':
        return 'bg-secondary';
      case 'pending':
        return 'bg-warning';
      case 'suspended':
      case 'deactivated':
        return 'bg-danger';
      case 'verified':
        return 'bg-success';
    }
  }

  selectFeature(feature: Features) {
    this.feature = feature;
    this.showDetails = true;
  }

  ngOnInit(): void {
    this.userService
      .getProfile()
      .pipe(take(1))
      .subscribe(({ profile: { firstName, lastName } }) => {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = this.auth.user?.email;
      });

    this.auth.role.subscribe(role => {
      this.isEmployer = role === Role.employer;
    });

    this.userService.getConfig().subscribe(configRes => {
      this.validationStatus = configRes?.config?.validation?.status;
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  async signOut() {
    this.teamService.clearTeamMembers();
    await this.auth.signOut();
    this.close();
  }
}
