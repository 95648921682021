import { Component, Inject, OnInit } from '@angular/core';
import { SubscriptionInfo, SubscriptionResponse, SubscriptionService } from '../services/subscription.service';
import { AuthService, Role } from '../services/auth.service';
import { ActivationService } from '../services/activation.service';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { objectToUrlParams } from '../utils/http';
import moment from 'moment/moment';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class SubscriptionComponent implements OnInit {
  subResp: SubscriptionResponse;
  loading = true;
  message = 'loading...<br/>';
  showSubscribePage = false;

  constructor(
    private activation: ActivationService,
    public subManager: SubscriptionService,
    private auth: AuthService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    // Reddit pixel page visit tracking
    if (typeof (window as any).rdt === 'function') {
      (window as any).rdt('init', 't2_ay89s8t8');
      (window as any).rdt('track', 'PageVisit');
    }

    const o = this.subManager.getSubscriptionInfo();
    o.subscribe(resp => {
      this.subResp = new SubscriptionResponse(resp);
      this.message = this.subResp.subscription.getMessage();
    });

    this.activation.getActivation().subscribe(actResp => {
      this.loading = false;
    });
  }

  canResubscribe(): boolean {
    return this.subResp?.subscription.canResubscribe();
  }

  goToCancellationForm() {
   
    const formId = this.auth.user.uid;

    const params = objectToUrlParams({ formId});
    window.open(`/forms/cancellationForm?${params}`, '_blank');
  }

  goToBillingPortal() {
    const referral = `${environment.firebase.server}${this.router.url}`;
    console.log(referral);
    this.subManager.getBillingPortalUrl(referral).subscribe(url => {
      window.open(url, '_blank');
    });
  }
}
