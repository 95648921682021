import { Component, Input, OnInit } from '@angular/core';
import { Paystub, PaystubService } from '../../services/paystub.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TeamMember, TeamService } from '../../services/team.service';
import { UserService } from '../../services/user.service';
import { LinkService } from '../../services/link.service';

@Component({
  selector: 'app-paystub-email',
  templateUrl: './paystub-email.component.html',
  styleUrls: ['./paystub-email.component.css'],
})
export class PaystubEmailComponent implements OnInit {
  @Input() paystub: Paystub;
  helper: TeamMember;
  autoEmailPaystub = true;
  lastSent: any;

  constructor(
    public modal: NgbActiveModal,
    private linkService: LinkService,
    private teamService: TeamService,
    private userService: UserService,
    private paystubService: PaystubService
  ) {}

  ngOnInit(): void {
    this.helper = this.teamService.getHelperInfo(this.userService.getLinkHelperProfileId(this.paystub.linkId));
    this.autoEmailPaystub = this.helper.link.employment.autoEmailPaystub;

    if (this.paystub.emailHistory) {
      const timestamps = Object.keys(this.paystub.emailHistory).map(
        key => new Date(this.paystub.emailHistory[key].timestamp)
      );
      this.lastSent = timestamps.reduce((a, b) => (a > b ? a : b));
    } else {
      this.lastSent = undefined;
    }
  }

  async sendEmail() {
    // Update auto email setting, but only if it has changed
    if (this.autoEmailPaystub !== this.helper.link.employment.autoEmailPaystub) {
      await this.linkService.updateAutoEmailPaystub(this.helper.linkId, this.autoEmailPaystub);
    }

    this.paystubService.sendPaystubEmail(this.paystub.id, this.helper.link.employer).then(res => {
      this.modal.close();
    });
  }
}
