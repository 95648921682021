import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent {
  @Input() title = 'Confirmation';
  @Input() warning = '';
  @Input() confirmText = 'OK';
  @Input() cancelText = 'Cancel';
  @Input() redPrimaryButton = true;
  private _message: string | SafeHtml;

  @Input()
  set message(message: string | SafeHtml) {
    this._message = typeof message === 'string' ? message : this.sanitizer.bypassSecurityTrustHtml(message as string);
  }

  get message(): string | SafeHtml {
    return this._message;
  }

  constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}

  close(result: boolean): void {
    this.activeModal.close(result);
  }
}
