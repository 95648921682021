import { Component, OnInit, Input } from '@angular/core';
import { Referral } from '../../services/referral.service';

@Component({
  selector: 'app-referral-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ReferralItemComponent implements OnInit {
  @Input() referral: Referral;
  constructor() { }

  ngOnInit() {
  }

}
