import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const stripped = value.replace(/\D/g, '');
    let formatted = '';

    if (stripped.length >= 10) {
      formatted = '(' + stripped.substring(0, 3) + ') ' + stripped.substring(3, 6) + '-' + stripped.substring(6);
    }

    return formatted;
  }
}
