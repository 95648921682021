import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscription-inactive-dialog',
  templateUrl: './subscription-inactive-dialog.component.html',
  styleUrls: ['./subscription-inactive-dialog.component.css'],
})
export class SubscriptionInactiveDialogComponent {
  constructor(public activeModal: NgbActiveModal) {}

  close(): void {
    this.activeModal.close(true);
  }
}
