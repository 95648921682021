import { Component, OnInit } from '@angular/core';
import { ReferralService, Referral } from '../services/referral.service';

@Component({
  selector: 'app-referral-manager',
  templateUrl: './referral-manager.component.html',
  styleUrls: ['./referral-manager.component.css']
})
export class ReferralManagerComponent implements OnInit {
  referrals: Referral[];
  isLoading = true;

  constructor(private referralService: ReferralService) { }

  ngOnInit() {
    this.referralService.getReferrals().subscribe(resp => {
      this.referrals = resp.referrals;
      this.isLoading = false;
    },
    err => {
      console.log(err.error.message);
      alert(err.error.message);
      this.isLoading = false;
    });
  }

}
