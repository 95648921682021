import { HostListener, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { getHeaders } from '../utils/http';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { decryptData, encryptData } from '../utils/string';

export interface PageField {
  title: string;
  type: string;
  param: string;
  defaultValue: string | number | string[] | number[] | boolean;
  disabled?: boolean;
  required?: boolean;
  options: number[];
}

export interface PageSection {
  header: string;
  hidden: string;
  rows: PageField[];
}

export interface PageResponse {
  status: string;
  pageId: string;
  object: {
    title: string;
    type: string;
    requestTime: string;
    formId: string;
    form: {
      ctaTitle: string;
      description: string;
      sections: PageSection[];
    };
  };
}

export interface SubmitPageResponse {
  status: string;
  formId: string;
  object: {
    warnings: {
      message: string;
    }[];
    nextStep: {
      type: string;
      object: string;
    };
  };
}

const formParamsKey = 'formParams';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  private pagesUrl = `https://${environment.firebase.server}/app/v1/pages`;
  private submitUrl = `https://${environment.firebase.server}/app/v1/submitForm`;

  set params(value: any) {
    sessionStorage.setItem(formParamsKey, encryptData(value));
  }

  get params(): any {
    const paramsFromStorage = sessionStorage.getItem(formParamsKey);
    return paramsFromStorage ? decryptData(paramsFromStorage) : {};
  }

  @HostListener('window:beforeunload')
  clearSessionData() {
    sessionStorage.removeItem(formParamsKey);
  }

  constructor(private auth: AuthService, private http: HttpClient) {}

  getPage = (pageId: string, paramsOverride?: any) => {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of({} as PageResponse);
        }
        return this.http.post<PageResponse>(
          this.pagesUrl,
          {
            pageId,
            params: paramsOverride ?? this.params,
          },
          { headers: getHeaders(t) }
        );
      })
    );
  };

  submitPage = (pageId: string, formId: string, data: any) => {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of({} as SubmitPageResponse);
        }
        return this.http
          .post<SubmitPageResponse>(
            this.submitUrl,
            {
              pageId,
              formId,
              doneTimestamp: new Date().toISOString(),
              submitted: {
                params: data,
              },
            },
            { headers: getHeaders(t) }
          )
          .pipe(
            catchError(e => {
              throw e;
            })
          );
      })
    );
  };
}
