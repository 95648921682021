import { Component, Inject, OnInit } from '@angular/core';
import { AuthService, Role } from '../services/auth.service';
import { SubscriptionService, SubscriptionInfo, SubscriptionResponse } from '../services/subscription.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ActivationService } from '../services/activation.service';

declare let fbq: (...args: any[]) => any;

@Component({
  selector: 'app-dashboard-old',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardOldComponent implements OnInit {
  subResp: SubscriptionResponse;
  message = 'loading...<br/>';
  isEmployer = true;
  loading = true;

  constructor(
    public auth: AuthService,
    public subManager: SubscriptionService,
    private activation: ActivationService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    const o = this.subManager.getSubscriptionInfo();
    o.subscribe(resp => {
      this.subResp = new SubscriptionResponse(resp);
      this.message = this.subResp.subscription.getMessage();
    });

    this.activation.getActivation().subscribe(actResp => {
      this.isEmployer = actResp?.activation.claims?.role === Role.employer;
      this.loading = false;
    });
  }

  canResubscribe(): boolean {
    return this.subResp?.subscription.canResubscribe();
  }

  openAppStore() {
    fbq('track', 'OpenAppStore');
    this.document.location.href = 'https://apps.apple.com/us/app/nest-payroll/id1314304473?mt=8';
    return false;
  }

  goToBillingPortal() {
    const referral = `${environment.firebase.server}${this.router.url}`;
    console.log(referral);
    this.subManager.getBillingPortalUrl(referral).subscribe(url => {
      this.document.location.href = url;
    });
  }
}
