import { Component, Input, OnInit } from '@angular/core';
import { PaystubService, Paystub, paystubToPaystubInput } from '../../services/paystub.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { PaystubCreateComponent } from '../paystub-create/paystub-create.component';
import { take } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import moment from 'moment';
import { PaystubPayComponent } from '../paystub-pay/paystub-pay.component';
import { SubscriptionService } from '../../services/subscription.service';
import { ProfileDialogComponent } from '../../profile/profile-dialog.component';
import { TeamMember } from '../../services/team.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-paystub-dialog',
  templateUrl: './paystub-dialog.component.html',
  styleUrls: ['./paystub-dialog.component.css'],
})
export class PaystubDialogComponent implements OnInit {
  @Input() paystub: Paystub;
  @Input() member: TeamMember;
  @Input() otherPartyName: string;
  isEmployer: boolean;
  subscriptionValid = true;
  loading = false;

  constructor(
    private paystubService: PaystubService,
    private modalService: NgbModal,
    private modal: ModalService,
    private auth: AuthService,
    public subscriptionService: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.auth.role.subscribe(async role => {
      this.isEmployer = role === 'employer';
    });

    this.subscriptionService.subscriptionIsValid$.subscribe(valid => {
      this.subscriptionValid = valid;
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  delete() {
    const modal = this.modalService.open(ConfirmationDialogComponent, { scrollable: true });
    modal.componentInstance.message = 'Are you sure you want to delete this paystub?';

    modal.result.then(result => {
      if (result) {
        this.loading = true;

        this.paystubService
          .deletePaystub(this.paystub.id)
          .pipe(take(1))
          .subscribe(
            () => {
              this.loading = false;
              this.modalService.dismissAll();
            },
            error => {
              this.loading = false;

              const message = this.modalService.open(MessageBoxComponent, { scrollable: true });
              message.componentInstance.title = 'Delete Failed';
              message.componentInstance.message = 'There was an error deleting this paystub.';

              message.result.then(() => {});
              console.error('Delete paystub error', error);
            }
          );
      }
    });
  }

  viewPDF() {
    this.loading = true;

    try {
      this.paystubService
        .getPaystubPdfData(this.paystub.id)
        .pipe(take(1))
        .subscribe(data => {
          try {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
          } catch (e) {
            console.error('Save PDF error', e);
          }

          this.loading = false;
        });
    } catch (e) {
      console.error('Save PDF error', e);
      this.loading = false;
    }
  }

  approve() {
    const message = `
      <p>
        You are about to approve the current pay stub for ${this.otherPartyName}.
      </p>
      Amount: $${this.paystub.net}<br />
      For the period ${moment(this.paystub.payStart).format('MMM D')} -
      ${moment(this.paystub.payEnd).format('MMM D, yyyy')}
    `;

    this.modal.showConfirmation('Approve Pay Stub', message, 'Yes, Approve', 'Dismiss', false).result.then(result => {
      if (result) {
        this.loading = true;

        this.paystubService
          .markPaystubPaid(this.paystub.id)
          .pipe(take(1))
          .subscribe(
            () => {
              this.loading = false;
              this.modalService.dismissAll();

              const payDialog = this.modalService.open(PaystubPayComponent, { scrollable: true });
              payDialog.componentInstance.paystub = this.paystub;
              payDialog.componentInstance.member = this.member;
              payDialog.componentInstance.paymentInstrument = this.member.link.employment.paymentInstrument;
            },
            error => {
              this.loading = false;

              const messageBox = this.modalService.open(MessageBoxComponent, { scrollable: true });
              messageBox.componentInstance.title = 'Approve Failed';
              messageBox.componentInstance.message = 'There was an error approving this paystub.';
              console.error('Approve paystub error', error);
            }
          );
      }
    });
  }

  handleEditClick() {
    this.modalService.dismissAll();

    const modal = this.modalService.open(PaystubCreateComponent, { size: 'md', scrollable: true });
    modal.componentInstance.member = this.member;
    modal.componentInstance.paystub = paystubToPaystubInput(this.paystub);
    modal.componentInstance.paystubId = this.paystub.id;
  }

  handlePayClick() {
    this.modalService.dismissAll();

    const modal = this.modalService.open(PaystubPayComponent, { scrollable: true });
    modal.componentInstance.paystub = this.paystub;
    modal.componentInstance.member = this.member;
    modal.componentInstance.paymentInstrument = this.member.link.employment?.paymentInstrument;
  }

  handleLearnMoreClick() {
    this.modalService.dismissAll();

    const modalRef = this.modalService.open(ProfileDialogComponent, { size: 'lg', fullscreen: 'lg', scrollable: true });
    modalRef.componentInstance.feature = 'subscription';
  }

  get canVoid() {
    const paidLessThan24HoursAgo = moment().diff(this.paystub.paid_date, 'hours') < 24;
    return this.isEmployer && this.paystub.status === 'paid' && paidLessThan24HoursAgo;
  }

  void() {
    this.modal
      .showConfirmation('Void Pay Stub', 'Do you want to void this pay stub? This cannot be undone.', 'Yes', 'No')
      .result.then(result => {
        if (!result) {
          return;
        }
        this.paystubService.updateStatus(this.paystub.id, 'canceled').subscribe(
          () => {
            this.modalService.dismissAll();
          },
          error => {
            console.error('Void paystub error', error);
          }
        );
      });
  }
}
