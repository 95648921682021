import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BankService } from '../services/bank.service';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { LinkRequest, LinkRequestConfirmation, LinkService } from '../services/link.service';
import { TaxInfo, UserService } from '../services/user.service';
import { maskAccountNumber, onKeyDownNumbersOnly } from '../utils/input';
import { AuthService } from '../services/auth.service';
import firebase from 'firebase/compat/app';

class FormFields {
  constructor(
    public routingNumber: string,
    public accountNumber: string,
    public accountNumberRepeat: string,
    public password: string,
    public passwordRepeat: string
  ) {}
}

@Component({
  selector: 'app-link-request',
  templateUrl: './link-request.component.html',
  styleUrls: ['./link-request.component.css'],
})
export class LinkRequestComponent implements OnInit, AfterViewInit {
  model = new FormFields('', '', '', '', '');

  @ViewChild('routingNumber') routingInput: NgModel;
  @ViewChild(NgForm) form!: NgForm;

  loading = false;
  submitted = false;
  success = false;
  error = '';
  requestId: string;
  request: LinkRequest;
  existingTaxInfo: TaxInfo;
  editingAccountDetails = false;
  userHasPassword = false;
  promptToCreatePassword = false;
  setPasswordSubmitted = false;
  setPasswordError: string;
  setPasswordSuccess = false;
  genericError = `Oops! Something went wrong. Please try again, or contact us any time at <a href="mailto:hello@nestpayroll.com?subject=Problem With Direct Deposit Enrollment">hello@nestpayroll.com</a>.`;
  onKeyDownNumbersOnly = onKeyDownNumbersOnly;

  constructor(
    public bankService: BankService,
    private linkService: LinkService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private afDatabase: AngularFireDatabase
  ) {}

  async getLinkRequest() {
    this.request = (await this.afDatabase.database.ref(`/linkRequest/${this.requestId}`).once('value')).val();
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.requestId = params.id;
      void this.getLinkRequest();
    });

    this.userService.getConfig().subscribe(({ config }) => {
      this.userHasPassword = config.hasPassword;
    });
  }

  ngAfterViewInit() {
    // Debounce routing number input
    this.routingInput.control.valueChanges.pipe(skip(1), debounceTime(500), distinctUntilChanged()).subscribe(value => {
      this.model.routingNumber = value;
      if (value.length === 9) {
        this.bankService.validateRoutingNumber(value);
      }
    });

    this.loading = true;

    const taxInfoResponse = this.userService.getTaxInfos();

    taxInfoResponse.subscribe(
      res => {
        this.loading = false;
        this.existingTaxInfo = res.taxInfos?.length ? res.taxInfos[0] : undefined;

        if (this.existingTaxInfo?.bankAccount) {
          this.model.accountNumber = maskAccountNumber(this.existingTaxInfo.bankAccount);
          this.model.accountNumberRepeat = maskAccountNumber(this.existingTaxInfo.bankAccount);
          this.model.routingNumber = this.existingTaxInfo.bankRouting;
        }
      },
      err => {
        this.loading = false;
        console.error('getTaxInfo error', err);
        this.error = this.genericError;
        this.success = false;
        this.loading = false;
      }
    );
  }

  // Returns true if the user had pre-existing tax info and they have not modified it
  hasUnmodifiedTaxInfo = () =>
    this.existingTaxInfo?.bankAccount &&
    this.model.accountNumber.includes('*') &&
    this.model.routingNumber === this.existingTaxInfo?.bankRouting;

  async onSubmitSetPassword() {
    this.setPasswordSubmitted = true;

    if (this.form.valid && this.model.password && this.model.password === this.model.passwordRepeat) {
      await this.authService.doSetPassword(this.model.password).subscribe(
        () => {
          this.setPasswordSuccess = true;
        },
        e => {
          console.error(e);
          this.setPasswordError = 'Your session has expired, so we cannot set your password at this time.';
          this.error = this.genericError;
        }
      );
    }
  }

  editAccountDetails() {
    this.editingAccountDetails = true;
    this.model.accountNumber = '';
    this.model.accountNumberRepeat = '';
  }

  onSubmit() {
    this.submitted = true;
    this.error = '';

    if (this.form.valid && this.model.accountNumber === this.model.accountNumberRepeat) {
      this.loading = true;

      try {
        const requestConfirmation: LinkRequestConfirmation = this.hasUnmodifiedTaxInfo()
          ? {
              bankAccount: this.existingTaxInfo.bankAccount,
              bankRouting: this.existingTaxInfo.bankRouting,
              confirmation: new Date().toISOString(),
            }
          : {
              bankAccount: this.model.accountNumber,
              bankRouting: this.model.routingNumber,
              confirmation: new Date().toISOString(),
            };

        const res = this.linkService.confirmLinkRequest(this.requestId, requestConfirmation);
        res.subscribe(
          () => {
            this.success = true;
            this.error = '';
            this.loading = false;

            this.promptToCreatePassword = !this.userHasPassword;
          },
          err => {
            if (err.error?.message === 'only the helper can approve this link request') {
              this.error =
                'This invitation can only be accepted by the user it was sent to. Please make sure you are logged in as the correct user.';
            } else {
              console.error('confirmLinkRequest error:', err);
              this.error = this.genericError;
            }
            this.success = false;
            this.loading = false;
          }
        );
      } catch (e) {
        console.error(e);
        this.loading = false;
      }
    }
  }
}
