import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, EMPTY, from, interval, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { getHeaders } from '../utils/http';
import { LinkInfo } from './link.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { SubscriptionService } from './subscription.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import moment from 'moment/moment';
import { AngularFireStorage } from '@angular/fire/compat/storage';

export interface Address {
  county: string;
  street: string;
  country: string;
  city: string;
  subLocality: string;
  postalCode: string;
  state: string;
  apartment?: string;
}

export interface Profile {
  id: string;
  email?: string;
  firstName: string;
  lastName: string;
  phone: string;
  dateOfBirth?: string;
  address: Address;
  mailingAddress?: Address;
  authorizationDate?: string;
  ssnAvailable?: string; // Last 4 digits of SSN
  timezone?: string;
}

export interface ProfileResponse {
  profile: Profile;
}

export interface ProfilePayload {
  profile: Profile;
  ssn?: string;
}

enum AccountStatus {
  profile = 'profile_incomplete',
  taxInfo = 'taxinfo_incomplete',
  authorizationUndefined = 'authorization_undefined',
  authorizationPending = 'authorization_pending',
  subscription = 'subscription',
  accountComplete = 'account_complete',
}

export interface TaxInfo {
  id: string;
  bankAccount: string;
  bankRouting: string;
  EIN?: string; // Federal EIN
  EDD?: string; // State EIN
}

export interface TaxInfoResponse {
  status: string;
  action: string;
  taxInfos: TaxInfo[];
}

export interface TaxSummary {
  [key: string]: {
    TotalCostToEmployer: number;
    TotalEmployeeFederalTax: number;
    TotalEmployeeStateTax: number;
    TotalEmployeeTaxWithholding: number;
    TotalEmployerFederalTax: number;
    TotalEmployerStateTax: number;
    TotalEmployerTax: number;
    TotalWages: number;
    Year: number;
  };
}

export type ValidationStatus =
  | 'unverified'
  | 'retry'
  | 'kba'
  | 'document'
  | 'unknown'
  | 'pending'
  | 'verified'
  | 'suspended'
  | 'deactivated';

export interface UserConfig {
  authorizationDate: string;
  is_admin: boolean;
  lock: {
    bankAccount: boolean;
    identity: boolean;
  };
  modifiedTime: string;
  profileId: string;
  taxInfoId: string;
  timezone: string;
  validation: {
    method: string;
    status: ValidationStatus;
    timestamp: string;
  };
  hasPassword?: boolean;
}

export interface ConfigResponse {
  status: string;
  action: string;
  config: UserConfig;
}

@Injectable({
  providedIn: 'root',
})
class UserService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private profileUrl = `https://${environment.firebase.server}/app/v2/profile`;
  private taxInfoUrl = `https://${environment.firebase.server}/app/v2/taxInfo`;
  private configUrl = `https://${environment.firebase.server}/app/v1/config`;
  public linkInfos: LinkInfo[] = [];
  private accountStatusRef: any;
  private pollForEmailVerification$ = new BehaviorSubject(false);
  private accountStatusCallback: (snapshot: any) => void;
  currentYear = moment().year().toString();
  verificationId: string;

  currentOnboardingStep$ = this.getAccountStatuses().pipe(
    map(statuses => {
      if (!statuses || statuses.length === 0 || statuses.includes(AccountStatus.profile)) {
        return 1;
      }
      if (statuses.includes(AccountStatus.taxInfo)) {
        return 2;
      }
      if (
        statuses.includes(AccountStatus.authorizationUndefined) ||
        statuses.includes(AccountStatus.authorizationPending)
      ) {
        return 3;
      }
      if (statuses.includes(AccountStatus.subscription)) {
        return 4;
      }
      if (statuses.includes(AccountStatus.accountComplete)) {
        return 5;
      }
    })
  );

  isEmailVerified$ = this.afAuth.authState.pipe(
    switchMap(user => {
      if (user) {
        return this.pollForEmailVerification$.pipe(
          switchMap(poll => {
            if (poll) {
              return interval(10000).pipe(
                startWith(0),
                switchMap(() =>
                  user.reload().then(() => {
                    if (user.emailVerified) {
                      this.pollForEmailVerification$.next(false);
                    }
                    return user.emailVerified;
                  })
                )
              );
            } else {
              return from(user.reload()).pipe(map(() => user.emailVerified));
            }
          })
        );
      } else {
        return of(false);
      }
    })
  );

  isMFAEnabled$ = this.afAuth.authState.pipe(
    switchMap(user => {
      if (user) {
        return from(user.reload()).pipe(
          map(() => {
            const enrolledFactors = user.multiFactor?.enrolledFactors;
            return enrolledFactors ? enrolledFactors.length > 0 : false;
          })
        );
      } else {
        return of(false);
      }
    })
  );

  taxSummary$ = this.afAuth.authState.pipe(
    switchMap(user => {
      if (!user) {
        return EMPTY;
      }

      return this.afDatabase.object<TaxSummary>(`/user/${user?.uid}/summary`).valueChanges();
    })
  );

  hasTaxSummary$ = this.taxSummary$.pipe(map(summary => summary?.[this.currentYear]?.TotalWages > 0));

  isOnboardingComplete$ = combineLatest([
    this.currentOnboardingStep$,
    this.subscriptionService.subscriptionIsValid$,
  ]).pipe(map(([currentStep, subscriptionActive]) => (currentStep > 2 && subscriptionActive) || currentStep > 3));

  isSecureAccountComplete$ = combineLatest([this.isEmailVerified$, this.isMFAEnabled$]).pipe(
    map(([isEmailVerified, isMfaEnabled]) => isEmailVerified && isMfaEnabled)
  );

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private afDatabase: AngularFireDatabase,
    private afStorage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private subscriptionService: SubscriptionService
  ) {
    void this.getAccountStatuses();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.accountStatusRef && this.accountStatusCallback) {
      this.accountStatusRef.off('value', this.accountStatusCallback);
    }
  }

  getProfile(profileId?: string): Observable<ProfileResponse> {
    return this.auth.idToken.pipe(
      takeUntil(this.destroy$),
      switchMap(t => {
        if (!t) {
          // Token not available, probably logged out, return a static value
          return of({ profile: {} as Profile });
        }

        return this.http
          .get<ProfileResponse>(this.profileUrl, {
            headers: getHeaders(t),
            params: profileId ? { profileId } : {},
          })
          .pipe(
            catchError(e => {
              console.error('getProfile error', e);
              return of({ profile: {} as Profile });
            })
          );
      })
    );
  }

  updateProfile(profilePayload: ProfilePayload): Observable<ProfileResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.put<ProfileResponse>(this.profileUrl, profilePayload, {
          headers: getHeaders(t),
        });
      })
    );
  }

  getTaxInfos(): Observable<TaxInfoResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of({ taxInfos: [] } as TaxInfoResponse);
        }

        return this.http.get<TaxInfoResponse>(this.taxInfoUrl, {
          headers: getHeaders(t),
        });
      })
    );
  }

  updateTaxInfo(taxInfo: TaxInfo): Observable<TaxInfoResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        return this.http.post<TaxInfoResponse>(this.taxInfoUrl, taxInfo, {
          headers: getHeaders(t),
          params: {
            taxInfoId: taxInfo.id,
          },
        });
      })
    );
  }

  getConfig(): Observable<ConfigResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of(null);
        }
        return this.http.get<ConfigResponse>(this.configUrl, {
          headers: getHeaders(t),
        });
      })
    );
  }

  getLinkEmployerProfileId(linkId: string): string {
    const info = this.linkInfos.find(l => l.linkId === linkId);
    return info ? info.link.profiles.employer : '';
  }

  getLinkHelperProfileId(linkId: string): string {
    const info = this.linkInfos.find(l => l.linkId === linkId);
    return info ? info.link.profiles.helper : '';
  }

  getAccountStatuses(): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const userId = user.uid;
          const accountStatusPath = `/user/${userId}/account_status`;

          return this.afDatabase.object(accountStatusPath).valueChanges();
        } else {
          return new BehaviorSubject(null).asObservable();
        }
      })
    );
  }

  sendEmailVerification() {
    this.afAuth.currentUser.then(user => {
      if (user) {
        user
          .sendEmailVerification()
          .then(() => {
            this.pollForEmailVerification$.next(true);
          })
          .catch(error => {
            console.error('Error sending email verification:', error);
          });
      }
    });
  }

  async enable2FAWithSMS(phoneNumber: string, appVerifier: firebase.auth.ApplicationVerifier) {
    const user = await this.afAuth.currentUser;

    if (user) {
      try {
        const phoneProvider = new firebase.auth.PhoneAuthProvider();
        const session = await user.multiFactor.getSession();

        this.verificationId = await phoneProvider.verifyPhoneNumber(
          {
            phoneNumber,
            session,
          },
          appVerifier
        );

        const verificationCode = prompt('Enter the verification code sent to your phone:');

        if (!verificationCode) {
          return;
        }

        const phoneCredential = firebase.auth.PhoneAuthProvider.credential(this.verificationId, verificationCode);

        const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(phoneCredential);
        await user.multiFactor.enroll(multiFactorAssertion, 'Phone Number');

        return;
      } catch (error) {
        console.error('Error enabling 2FA with SMS:', JSON.stringify(error));

        return error;
      }
    }
  }

  getTaxSummary() {
    const userId = this.auth.user.uid;
    this.accountStatusRef = this.afDatabase.database.ref(`/user/${userId}/summary`);
  }

  async getFiles() {
    return (await this.afDatabase.database.ref(`/user/${this.auth.user.uid}/files`).once('value')).val();
  }

  async getFileUrl(path: string): Promise<string> {
    try {
      const ref = this.afStorage.ref(path);
      return await ref.getDownloadURL().toPromise();
    } catch (error) {
      console.error('Error fetching PDF file:', error);
      throw error;
    }
  }

  setTimezone(timezone: string) {
    // const userId = this.auth.user.uid;
    // let r = this.afDatabase.database.ref(`/user/${userId}/profile`).update({timezone});
    this.getProfile()
      .pipe(take(1))
      .subscribe(({ profile }) => {
        this.updateProfile({ profile: { ...profile, timezone } })
          .pipe(take(1))
          .subscribe();
      });
  }
}

export { UserService };
