import { Component, Input, OnInit } from '@angular/core';
import { Paystub } from '../../services/paystub.service';
import { AuthService, Role } from '../../services/auth.service';
import { getPaystubStatusBadgeClass } from 'src/app/utils/ui';

@Component({
  selector: 'app-paystub-details',
  templateUrl: './paystub-details.component.html',
  styleUrls: ['./paystub-details.component.css'],
})
export class PaystubDetailsComponent implements OnInit {
  @Input() paystub: Paystub;
  @Input() otherPartyName: string;
  isEmployer = true;
  totalHours = 0;

  getPaystubStatusBadgeClass = getPaystubStatusBadgeClass;

  getEmailedDate() {
    const keys = Object.keys(this.paystub.emailHistory);
    return this.paystub.emailHistory[keys[keys.length - 1]]?.timestamp;
  }

  constructor(public auth: AuthService) {}

  ngOnInit(): void {
    this.auth.role.subscribe(role => {
      this.isEmployer = role === Role.employer;
    });

    this.totalHours =
      this.paystub.hours +
      (this.paystub.secondaryHours ?? 0) +
      (this.paystub.overtime ?? 0) +
      (this.paystub.vacationHours ?? 0) +
      (this.paystub.sickHours ?? 0) +
      (this.paystub.doubletime ?? 0);

    console.log('paystub', this.paystub);
  }

  hasReimbursements(paystub: Paystub) {
    return paystub.incidentals?.healthCare > 0 || paystub.incidentals?.other > 0;
  }
}
