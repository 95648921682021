import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { onKeyDownNumbersOnly } from '../../utils/input';

@Component({
  selector: 'app-toggle-input-field',
  templateUrl: './toggle-input-field.component.html',
  styleUrls: ['./toggle-input-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleInputFieldComponent),
      multi: true,
    },
  ],
})
export class ToggleInputFieldComponent implements ControlValueAccessor {
  @Input() subLabel: string;
  @Input() subFieldLabel: string;
  @Input() subFieldValue: string;
  @Input() control: FormControl;
  @Input() numbersOnly: boolean;
  @Input() currency: boolean;
  @Input() className = '';
  showInput = false;
  protected readonly onKeyDownNumbersOnly = onKeyDownNumbersOnly;

  onChange = (value: any) => {};

  onTouched = () => {};

  toggleInput() {
    this.showInput = !this.showInput;

    if (!this.showInput) {
      this.control.setValue('');
      this.onChange('');
      this.onTouched();
    }
  }

  public updateValue(val: any) {
    this.onChange(this.numbersOnly ? parseFloat(val.replace('$', '').replace(/,/g, '')) : val); // this updates the form control value
    this.onTouched(); // this updates the touch status
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.control.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
