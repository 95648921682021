import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

// This component handles /auth/action routes and renders or redirects to the appropriate component to process the request
@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.css'],
})
export class AuthActionComponent implements OnInit {
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  mode: string;
  actionCode: string;
  continueUrl: string;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.mode = params.mode;
      this.actionCode = params.oobCode;
      this.continueUrl = params.continueUrl;

      if (this.mode === 'verifyEmail') {
        this.router.navigate(['/verifyEmail'], { queryParams: { code: this.actionCode } });
      } else if (this.mode === 'revertSecondFactorAddition') {
        this.router.navigate(['/revertSecondFactor'], { queryParams: { code: this.actionCode } });
      }
    });
  }
}
