import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, Role } from '../services/auth.service';
import { ActivationService } from '../services/activation.service';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { capitalize } from '../utils/string';

declare let gtag: (...args: any[]) => any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  username: string;
  password: string;
  confirmPassword: string;
  agree: boolean;
  redirect?: string;
  isLoading = true;
  referral: string;
  userRole?: Role;
  userTypeDescription?: string;
  doingSignup = false;
  Role = Role;
  activationCode: string;
  partnership: string;

  accountTypeSelectionEnabled = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private actManager: ActivationService,
    private cookieService: CookieService
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.redirect = params.redirect;
    });

    if (!this.accountTypeSelectionEnabled) {
      this.userRole = Role.employer;
      this.userTypeDescription = 'Employer';
    }
  }

  ngOnInit() {

    const cookieVal = this.cookieService.get('referral');
    this.referral = cookieVal !== '' ? cookieVal : null;
    this.partnership = this.referral != null ? `In partnership with ${capitalize(this.referral)}` : ''
    this.isLoading = true;

    this.auth.userState.subscribe(state => {
      if (state && (!this.activationCode || (state.userRole && state.userRole !== this.userRole))) {
        if (this.userRole === Role.employer) {
          this.activationCode = environment.production ? '9753' : '8787';
        } else if (this.userRole === Role.helper) {
          this.activationCode = environment.production ? '5469' : '5468';
        }

        if (this.activationCode) {
          const activationSubscription = this.actManager.sendActivation(this.activationCode, this.referral).subscribe(
            () => {
              activationSubscription.unsubscribe();
              this.isLoading = true;
              void this.router.navigate(['dashboard']);
            },
            e => {
              console.error('sendActivation error', e);
              this.isLoading = false;
            }
          );
        } else {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    });
  }

  async selectUserRole(role?: Role) {
    this.userRole = role;
    if (role === Role.employer) {
      this.userTypeDescription = 'Employer';
    } else if (role === Role.helper) {
      this.userTypeDescription = 'Household Employee';
    } else {
      this.userTypeDescription = '';
    }
  }

  async doSignup() {
    if (this.userRole == null) {
      alert('Must select a user type');
      return;
    }
    if (!this.username || !this.password) {
      alert('Username and Password are required fields');
      return;
    }
    if (this.password !== this.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    if (!this.agree) {
      alert('Please check and accept our terms and conditions');
      return;
    }

    try {
      this.isLoading = true;
      this.doingSignup = true;
      const user = await this.auth.doSignup(this.username, this.password);
      let code: string = null;

      if (this.userRole === Role.employer) {
        code = environment.production ? '9753' : '8787';
      } else if (this.userRole === Role.helper) {
        code = environment.production ? '5469' : '5468';
      } else {
        throw new Error('must provide a user role');
      }

      const activationSubscription = this.actManager.sendActivation(code, this.referral).subscribe(async resp => {
        activationSubscription.unsubscribe();
        this.isLoading = false;
        await user.getIdTokenResult(true); // force a refresh for idTokenResult since activation will add custom claims
        await this.router.navigate(['/dashboard']);
      });

      gtag('event', 'conversion', { send_to: 'AW-850295643/hZNeCOeU7cEBENv2uZUD' });
    } catch (e) {
      this.isLoading = false;

      if (e.code === 'auth/email-already-in-use') {
        alert('This email is already in use. Please try another or login');
        return;
      } else if (e.code === 'auth/invalid-email') {
        alert('Please enter a valid email address');
        return;
      } else if (e.code === 'auth/network-request-failed') {
        alert('We had trouble contacting the server. Please try again.');
        return;
      }

      alert('Error creating account. Please try again.');
    }
  }
}
