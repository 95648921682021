import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-array-input',
  templateUrl: './form-array-input.component.html',
  styleUrls: ['./form-array-input.component.css'],
})
export class FormArrayInputComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() submitted: boolean;
  @Input() controlName: string;
  @Input() title: string;
  @Input() required?: boolean;
  @Input() options: any[];

  ngOnInit() {}

  get control() {
    return this.group.controls[this.controlName] as FormArray;
  }

  onSelect(options) {
    const selectedOptions = Array.from(options as HTMLOptionElement[])
      .filter(x => x.selected)
      .map(x => x.value);

    this.control.clear();

    for (const opt of selectedOptions) {
      this.control.push(new FormControl(opt));
    }
  }

  isInvalid() {
    return this.group.get(this.controlName)?.invalid && this.submitted;
  }
}
