import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService, Role } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { mergeMap } from 'rxjs/operators';
import { getHeaders } from '../utils/http';
import { Profile } from './user.service';

@Injectable({
  providedIn: 'root',
})
class LinkService {
  private serverUrl = `https://${environment.firebase.server}/app/v1`;

  constructor(private auth: AuthService, private http: HttpClient, private afDatabase: AngularFireDatabase) {}

  confirmLinkRequest(requestId: string, confirmation: LinkRequestConfirmation): Observable<LinkRequestConfirmation> {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.put<LinkRequestConfirmation>(`${this.serverUrl}/linkRequest/confirm/${requestId}`, confirmation, {
          headers: getHeaders(t),
        })
      )
    );
  }

  updateLinkStatus(linkId: string, status: LinkStatus) {
    return this.afDatabase.database.ref(`/links/${linkId}/status`).set(status);
  }

  updateWageSchedule(linkId: string, wageSchedule: WageSchedule) {
    return this.afDatabase.database.ref(`/links/${linkId}/wageSchedule`).set(wageSchedule);
  }

  deleteWageSchedule(linkId: string) {
    return this.afDatabase.database.ref(`/links/${linkId}/wageSchedule`).remove();
  }

  async getLink(userId: string, role: Role): Promise<any> {
    const res = await this.afDatabase.database.ref('/links').orderByChild(role).equalTo(userId).once('value');

    return res.val();
  }
}

export interface LinkRequestConfirmation {
  bankAccount: string;
  bankRouting: string;
  confirmation: string;
}

export interface EmailHistory {
  email: string;
  timestamp: string;
}

export type PaymentInstrument = 'venmo' | 'cashapp' | 'ach' | 'other' | 'unknown';

export interface W4 {
  allowancesChild: number;
  allowancesOther: number;
  federalAdditionalWithholding: number;
  federalExempt: boolean;
  ficaExempt: boolean;
  futaExempt: boolean;
  localExempt: boolean;
  stateAdditionalWithholding: number;
  stateAllowances: number;
  stateExempt: boolean;
  status: 'single' | 'married' | 'headOfHousehold';
  taxExempt: boolean;
}

export interface Employment {
  autoEmailPaystub: boolean;
  hourlyRate: number;
  isNewHire: boolean;
  leaveSettingsSet: boolean;
  startDate: string;
  paymentInstrument: PaymentInstrument;
  startOfWeek: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
  title: string;
  sickTotals: { [key: string]: number };
  vacationTotals: { [key: string]: number };
  w4: W4;
  stateConfig: {
    [key: string]: {
      RSPoptIn?: boolean;
      LSToptIn?: boolean;
      FLIoptIn?: boolean;
      RSPpercentage?: number;
      LSTAmount?: number;
    };
  };
}

export interface WageSchedule {
  autoPayAch: boolean;
  dayOfWeek: string;
  hours: number;
  incidentals: {
    healthCare: number;
    other: number;
  };
  incidentalsAmount: number;
  interval: number;
  overtime: number;
  startDate: string;
  weekday: number;
}

export interface Link {
  created: string;
  directDeposit: {
    createdAt: string;
  };
  employer: string;
  employment: Employment;
  fundingSourceId: string;
  helper: string;
  linkRequestId: string;
  profiles: {
    employer: string;
    helper: string;
    oldHelperProfileId: string;
  };
  wageSchedule?: WageSchedule;
  status: string;
  updated: string;
}

export interface LinkInfo {
  linkId: string;
  link: Link;
}

export interface LinkRequest {
  confirmation: {
    employer?: string;
    helper?: string;
  };
  createdTimestamp: string;
  emailHistory: Record<string, EmailHistory>;
  employer: {
    email: string;
    name: string;
    picURL: string;
    profileId: string;
    uid: string;
  };
  employerEmail: string;
  helper: {
    email: string;
    name: string;
    profileId: string;
    uid: string;
  };
  helperEmail: string;
  linkId: string;
}

export interface TeamMemberPayload {
  status: string;
  ssn: string;
  flags: {
    overrideStateCheck: boolean;
    movePayroll: boolean;
    catchupCombined: boolean;
    newHireReport: boolean;
    setupDirectDeposit: boolean;
  };
  linkData: {
    employment: Employment;
  };
  idempotencyKey?: string;
  profile: Profile;
}

export type LinkStatus = 'active' | 'inactive' | 'terminated';

export { LinkService };
