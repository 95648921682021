import { Component, OnInit } from '@angular/core';
import { TaxSummary, UserService } from '../../services/user.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ModalService } from '../../services/modal.service';

export interface FileInfo {
  model: {
    id: string;
    file: {
      location: string;
      path: string;
      status: string;
      timestamp: string;
    };
    rows: [
      {
        detail: string;
        id: string;
        title: string;
        type: string;
      }
    ];
    title: string;
  };
  type: string;
}

@Component({
  selector: 'app-tax-summary-details',
  templateUrl: './tax-summary-details.component.html',
  styleUrls: ['./tax-summary-details.component.css'],
})
export class TaxSummaryDetailsComponent implements OnInit {
  taxSummary: TaxSummary;
  years: number[] = [];
  yearsReverse: number[] = [];
  year: number;
  files: { [key: string]: FileInfo }[] = [];

  constructor(
    private userService: UserService,
    private dashboardService: DashboardService,
    private modal: ModalService
  ) {}

  ngOnInit(): void {
    this.userService.taxSummary$.subscribe(taxSummary => {
      this.taxSummary = taxSummary;
      this.years = Object.keys(this.taxSummary)?.map(Number);
    });

    void this.loadFiles();
  }

  async loadFiles() {
    this.files = await this.userService.getFiles();
    this.years = Object.keys(this.taxSummary)?.map(Number);
    this.yearsReverse = this.years.slice().reverse();
    this.year = this.years.length ? this.years[this.years.length - 1] : new Date().getFullYear();
  }

  async onFileClick(event: Event, file: FileInfo) {
    event.preventDefault();

    if (!file.model.file) {
      return;
    }

    const url = await this.userService.getFileUrl(file.model.file.location);
    this.modal.openInNewTab(url);
  }

  onClose() {
    this.dashboardService.setFeature('');
  }
}
