import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css'],
})
export class MessageBoxComponent {
  @Input() title: string;
  @Input() message: string;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  close(): void {
    this.activeModal.close(true);
  }

  public showMessage(title: string, message: string) {
    const modal = this.modalService.open(MessageBoxComponent, { scrollable: true });
    this.title = title;
    this.message = message;

    modal.result.then(result => {
      return;
    });
  }
}
