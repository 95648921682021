import { HttpHeaders } from '@angular/common/http';

export function getHeaders(token: string) {
  const headers = new HttpHeaders();
  return headers.set('Content-Type', 'application/json; charset=utf-8').set('Authorization', `Bearer ${token}`);
}

export function objectToUrlParams(obj: any, prefix?: string): string {
  const queryString = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const paramKey = prefix ? `${prefix}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

      if (value !== null && typeof value === 'object') {
        queryString.push(objectToUrlParams(value, paramKey));
      } else {
        queryString.push(`${paramKey}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryString.join('&');
}

export function urlParamsToObject(queryString: string): any {
  const obj: any = {};

  // Remove the leading '?' if present
  const params = queryString.replace(/^\?/, '').split('&');

  params.forEach(param => {
    let [key, value] = param.split('=');
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);

    // Parse the key to extract nested keys
    const keyParts = [];
    const keyRegex = /([^\[\]]+)|\[([^\[\]]*)\]/g;
    let match;
    while ((match = keyRegex.exec(key)) !== null) {
      keyParts.push(match[1] || match[2]);
    }

    // Build the nested object
    let current = obj;
    for (let i = 0; i < keyParts.length; i++) {
      const part = keyParts[i];
      if (i === keyParts.length - 1) {
        // Assign the value at the deepest level
        current[part] = parseValue(value);
      } else {
        // Ensure the current level is an object
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      }
    }
  });

  return obj;
}

export function injectFormId(params: object, formId: string) {
  if (!params) {
    return {
      formId,
    };
  }
  return Object.keys(params).includes('formId')
    ? params
    : {
        ...params,
        formId,
      };
}

// Parse URL param values to appropriate types
function parseValue(value: string): any {
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (isNumeric(value)) return Number(value);
  return value;
}

// Check if a string is a number. Returns false if it's a date string.
function isNumeric(value: string): boolean {
  // Regular expression to test if the entire string is a number
  return /^-?\d+(\.\d+)?$/.test(value);
}
