import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Form, FormsService } from '../../services/forms.service';
import { DashboardService } from '../../dashboard/dashboard.service';
import { DOCUMENT } from '@angular/common';
import { injectFormId, objectToUrlParams } from '../../utils/http';

@Component({
  selector: 'app-pending-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.css'],
})
export class PendingListComponent implements OnInit {
  forms: Form[];

  constructor(
    public auth: AuthService,
    private formsService: FormsService,
    private dashboardService: DashboardService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.formsService.observeForms(this.auth.user.uid).subscribe(forms => {
      this.forms = Object.values(forms).filter((form: any) => !form.submitted && !form.doneTimestamp);
    });
  }

  onClose() {
    this.dashboardService.setFeature('');
  }

  showForm(form: any) {
    this.document.location.href = `/forms/${form.pageId}?${objectToUrlParams(injectFormId(form.params, form.formId))}`;
  }
}
