import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SubscriptionService } from './subscription.service';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthService, Role } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate {
  constructor(private auth: AuthService, private sub: SubscriptionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.role.pipe(
      mergeMap(role => {
        if (role === Role.helper) {
          return of(true);
        }
        return this.sub.getSubscriptionInfo().pipe(
          mergeMap(resp => {
            if (resp.subscription && resp.subscription.subscriptionType !== 'none') {
              return of(true);
            }
            this.router.navigate(['/subscribe']);
            return of(false);
          })
        );
      })
    );
  }
}
