import { Component, Input, OnInit } from '@angular/core';
import { PaystubInput, PaystubService, YtdHours } from '../../services/paystub.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { dateToNgbDate, ngbDateToDate, onKeyDownNumbersOnly } from '../../utils/input';
import { getLastStartOfWeek, Weekday } from '../../utils/date';
import { take } from 'rxjs/operators';
import { ModalService } from '../../services/modal.service';
import { PaystubDialogComponent } from '../paystub-details/paystub-dialog.component';
import { TeamMember, TeamService } from '../../services/team.service';
import { formatCurrency } from '@angular/common';
import { toFloat } from 'src/app/utils/string';
import { AuthService, Role } from '../../services/auth.service';
import { LinkService } from '../../services/link.service';

interface DayOfWeek {
  name: Weekday;
  abbreviation: 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
  number?: number;
}

@Component({
  selector: 'app-paystub-create',
  templateUrl: './paystub-create.component.html',
  styleUrls: ['./paystub-create.component.css'],
})
export class PaystubCreateComponent implements OnInit {
  protected readonly onKeyDownNumbersOnly = onKeyDownNumbersOnly;
  protected readonly formatCurrency = formatCurrency;
  ytdHours: YtdHours;
  @Input() member: TeamMember;
  @Input() paystub: PaystubInput;
  @Input() paystubId: string;

  currentPayStubStart: moment.Moment;

  daysOfWeek: DayOfWeek[] = [
    {
      name: 'sunday',
      abbreviation: 'SUN',
      number: 1,
    },
    {
      name: 'monday',
      abbreviation: 'MON',
      number: 2,
    },
    {
      name: 'tuesday',
      abbreviation: 'TUE',
      number: 3,
    },
    {
      name: 'wednesday',
      abbreviation: 'WED',
      number: 4,
    },
    {
      name: 'thursday',
      abbreviation: 'THU',
      number: 5,
    },
    {
      name: 'friday',
      abbreviation: 'FRI',
      number: 6,
    },
    {
      name: 'saturday',
      abbreviation: 'SAT',
      number: 7,
    },
  ];
  dateRange: { fromDate: NgbDateStruct; toDate: NgbDateStruct };
  bonusControl = new FormControl('');
  doubletimeControl = new FormControl('');
  alternateHoursControl = new FormControl('');
  alternateRateControl = new FormControl('');
  vacationHoursControl = new FormControl('');
  sickHoursControl = new FormControl('');
  healthCareControl = new FormControl('');
  otherIncidentalsControl = new FormControl('');
  recurring = false;
  directDeposit = false;
  submitted = false;
  dayOfWeek: DayOfWeek = {
    name: 'sunday',
    abbreviation: 'SUN',
    number: 1,
  };
  startDayOfWeek: Weekday;
  nextPayStub = '';
  loading = false;
  isEdit = false;
  isEmployer = false;
  year = new Date().getFullYear();
  sickBalance: number | undefined = undefined;
  vacationBalance: number | undefined = undefined;
  protected readonly toFloat = toFloat;

  getNextPayStubDate() {
    const today = moment().weekday() + 1;
    const days = this.dayOfWeek.number <= today ? 7 : 0;
    const nextPayStubDate = this.currentPayStubStart.day(this.dayOfWeek.abbreviation).clone().add(days, 'days');
    this.nextPayStub = nextPayStubDate.format('M/DD');
    return nextPayStubDate;
  }

  constructor(
    private paystubService: PaystubService,
    private modalService: NgbModal,
    private userService: UserService,
    private modal: ModalService,
    private auth: AuthService,
    private teamService: TeamService,
    private linkService: LinkService
  ) {}

  ngOnInit(): void {
    this.startDayOfWeek = this.member.link.employment.startOfWeek ?? 'sunday';
    this.dayOfWeek = this.daysOfWeek.find(d => d.name === this.startDayOfWeek);

    this.paystubService.getYtdHours(this.member.linkId, this.year).then(
      ytdHours => {
        this.ytdHours = ytdHours;

        if (this.member.link.employment.vacationTotals?.[this.year]) {
          this.vacationBalance = this.ytdHours.vacationBalance;
        }

        if (this.member.link.employment.sickTotals?.[this.year]) {
          this.sickBalance = this.ytdHours.sickBalance;
        }
      },
      () => {}
    );

    this.auth.role.subscribe(role => {
      this.isEmployer = role === Role.employer;
    });

    if (this.paystub) {
      this.isEdit = true;

      // If incidentals are not set, set them to 0, otherwise form binding errors will cause fields not to show
      if (!this.paystub.wage.incidentals) {
        this.paystub.wage.incidentals = {
          healthCare: 0,
          other: 0,
        };
      }
    } else {
      this.isEdit = false;

      this.paystub = {
        linkId: this.member.linkId,
        wage: {
          hourlyRate: this.member.link.employment.hourlyRate,
          payStart: undefined,
          payEnd: undefined,
          hours: 0,
          incidentalsAmount: 0,
          incidentals: {
            healthCare: 0,
            other: 0,
          },
          overtime: 0,
          doubletime: 0,
          secondaryHours: 0,
          secondaryHourlyRate: 0,
          vacationHours: 0,
          sickHours: 0,
          bonus: 0,
        },
        location: {
          state: '',
          employerCity: '',
          helperCity: this.member.address.city,
          helperCounty: this.member.address.county,
          helperState: this.member.address.state,
        },
        timezone: moment.tz.guess(),
      };

      this.userService
        .getProfile()
        .pipe(take(1))
        .subscribe(
          ({
            profile: {
              address: { state, city },
            },
          }) => {
            this.paystub = {
              ...this.paystub,
              location: {
                ...this.paystub.location,
                state,
                employerCity: city,
              },
            };
          }
        );
    }

    // Get the last occurrence of the day of week the employer pays on. If the employer pays on Sunday and today is Sunday,
    // then set this to the Sunday one week ago.
    const startDay = getLastStartOfWeek(this.member.link.employment.startOfWeek ?? 'sunday');

    this.currentPayStubStart = this.paystub?.wage.payStart ? moment(this.paystub?.wage.payStart) : startDay;

    this.dateRange = {
      fromDate: dateToNgbDate(this.currentPayStubStart.toDate()),
      toDate: dateToNgbDate(this.currentPayStubStart.clone().add(6, 'days').toDate()),
    };

    this.getNextPayStubDate();
  }

  get nextPayStubDate() {
    const today = moment().weekday() + 1;
    const days = this.member.link.wageSchedule?.weekday <= today ? 7 : 0;
    const nextPayStubDate = moment().day(this.member.link.wageSchedule?.weekday - 1 + days);
    return nextPayStubDate.format('M/DD');
  }

  selectDayOfWeek(dayOfWeek: DayOfWeek) {
    this.dayOfWeek = dayOfWeek;
    this.getNextPayStubDate();
  }

  showDetails() {
    this.loading = true;

    this.paystubService
      .getPaystub(this.paystubId)
      .pipe(take(1))
      .subscribe(
        res => {
          this.loading = false;
          this.modalService.dismissAll();

          const modal = this.modalService.open(PaystubDialogComponent, { scrollable: true });
          modal.componentInstance.member = this.member;
          modal.componentInstance.paystub = res;
          modal.componentInstance.otherPartyName = this.paystubService.getPaystubName(res, this.isEmployer);
        },
        err => {
          this.loading = false;
          console.error(err);
          this.modal.showMessage('Error', 'An error occurred while retrieving the paystub.');
        }
      );
  }

  submit() {
    const ps = {
      ...this.paystub,
      wage: {
        ...this.paystub.wage,
        payStart: moment(ngbDateToDate(this.dateRange.fromDate)).format(),
        payEnd: moment(ngbDateToDate(this.dateRange.toDate)).format(),
      },
    };

    if (
      !ps.wage.hours &&
      !ps.wage.overtime &&
      !ps.wage.doubletime &&
      !ps.wage.secondaryHours &&
      !ps.wage.incidentalsAmount &&
      !ps.wage.bonus &&
      !ps.wage.incidentals.other &&
      !ps.wage.incidentals.healthCare
    ) {
      this.modal.showMessage('Empty Paystub', 'Please fill out at least one of the fields in the paystub.');
      return;
    }

    this.submitted = true;

    this.loading = true;

    const wageSchedule = this.recurring
      ? {
          autoPayAch: this.directDeposit,
          dayOfWeek: this.dayOfWeek.name,
          hours: this.paystub.wage.hours,
          incidentals: this.paystub.wage.incidentals,
          incidentalsAmount: this.paystub.wage.incidentalsAmount,
          interval: 1,
          overtime: this.paystub.wage.overtime,
          startDate: this.getNextPayStubDate().format(),
          weekday: this.dayOfWeek.number,
        }
      : undefined;

    if (this.isEdit) {
      this.paystubService
        .updatePaystub(this.paystubId, ps)
        .pipe(take(1))
        .subscribe(
          res => {
            setTimeout(() => {
              this.loading = false;
              this.showDetails();
            }, 1000);
          },
          err => {
            this.loading = false;
            console.error(err);

            this.modal.showMessage('Error', 'An error occurred while saving the paystub.');
          }
        );
    } else if (this.recurring) {
      this.linkService
        .updateWageSchedule(this.member.linkId, wageSchedule)
        .then(() => {
          this.teamService.updateTeamMemberLocally({
            ...this.member,
            link: {
              ...this.member.link,
              wageSchedule,
            },
          });
          this.loading = false;
          this.close();
        })
        .catch(e => {
          this.loading = false;
          console.error(e);

          this.modal.showMessage('Error', 'An error occurred while saving the paystub.');
        });
    } else {
      void this.paystubService
        .createPaystub({
          ...ps,
          linkId: this.member.linkId,
        })
        .pipe(take(1))
        .subscribe(
          res => {
            this.loading = false;
            this.paystubId = res;
            this.showDetails();
          },
          err => {
            this.loading = false;
            console.error(err);

            this.modal.showMessage('Error', 'An error occurred while creating the paystub.');
          }
        );
    }
  }

  close() {
    this.modalService.dismissAll();
  }
}
