import { Component, OnInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-ssn-input',
  templateUrl: './ssn-input.component.html',
  styleUrls: ['./ssn-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SsnInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SsnInputComponent),
      multi: true,
    },
  ],
})
export class SsnInputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = 'Enter social security number';
  @Input() label = 'SSN';
  @Output() validationError = new EventEmitter<ValidationErrors>();

  public ssn = '';
  public ssnMask: string;
  public customPatterns = {
    0: { pattern: /[0-9]/ }, // Accept digits
    A: { pattern: /[X0-9]/ }, // Accept 'X' or digits
  };
  public isInvalid = false;
  disabled = false;

  private initialSsn: string;
  private ssnCleared = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  ngOnInit() {
    if (this.ssn) {
      // Initialize with masked SSN if available
      this.ssn = this.maskSsn(this.ssn);
      this.initialSsn = this.ssn; // Store initial masked SSN
      this.ssnMask = 'AAA-AA-0000';
    } else {
      this.ssnMask = '000-00-0000';
    }
  }

  validate(): ValidationErrors | null {
    const rawSsn = this.getRawSsn();

    // Don't show error if length is 4 (last 4 of SSN) or 9 (full SSN)
    if (rawSsn.length === 4 || rawSsn.length === 9) {
      this.validationError.emit(null);
      return null;
    } else {
      const error = { invalidSsn: 'Social security number must be 9 digits.' };
      this.validationError.emit(error);
      return error;
    }
  }

  onSsnFocus() {
    this.isInvalid = false;

    if (this.ssn.startsWith('XXX-XX-')) {
      this.ssn = '';
      this.ssnCleared = true;
    }
    this.ssnMask = '000-00-0000';
  }

  onSsnBlur() {
    const rawSsn = this.getRawSsn();
    if (rawSsn.length === 9) {
      this.ssn = this.maskSsn(rawSsn);
      this.ssnMask = 'AAA-AA-0000';
      this.onChange(rawSsn); // Pass the raw SSN to parent
      this.ssnCleared = false; // Reset the flag
    } else if (rawSsn.length === 0 && this.ssnCleared) {
      // User didn't type anything, restore initial value
      this.ssn = this.initialSsn;
      this.ssnMask = 'AAA-AA-0000';
      this.ssnCleared = false; // Reset the flag
    } else if (rawSsn.length > 0 && rawSsn.length < 9) {
      this.isInvalid = true;
    }
    this.onTouched();
  }

  onSsnChange(value: string) {
    this.ssn = value;
    const rawSsn = this.getRawSsn();
    this.onChange(rawSsn);
  }

  private getRawSsn(): string {
    return this.ssn.replace(/\D/g, '');
  }

  private maskSsn(ssn: string): string {
    return 'XXX-XX-' + ssn.slice(-4);
  }

  writeValue(value: any): void {
    if (value) {
      this.ssn = this.maskSsn(value);
      this.initialSsn = this.ssn; // Update initialSsn when value is set
      this.ssnMask = 'AAA-AA-0000';
    } else {
      this.ssn = '';
      this.ssnMask = '000-00-0000';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
