import { Injectable, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { getHeaders } from '../utils/http';

@Injectable({
  providedIn: 'root',
})
class SubscriptionService {
  private billingPortalUrl = `https://${environment.firebase.server}/app/v1/billingPortal`;
  private purchaseUrl = `https://${environment.firebase.server}/app/v1/purchase`;
  private plansUrl = `https://${environment.firebase.server}/app/v2/plans`;
  private couponUrl = `https://${environment.firebase.server}/app/v1/validateCoupon`;
  public subscription = new BehaviorSubject<SubscriptionInfo>(null);

  constructor(private auth: AuthService, private http: HttpClient, private cookieService: CookieService) {
    this.getSubscriptionInfo().subscribe(resp => {
      this.subscription.next(resp.subscription);
    });
  }

  get subscriptionIsValid$() {
    return this.subscription.asObservable().pipe(map(sub => sub?.is_valid === true));
  }

  get canAddTeamMembers$() {
    return this.subscription
      .asObservable()
      .pipe(map(sub => sub?.details?.current_active_employees < sub?.details?.max_employees));
  }

  public incrementEmployeeCount = () => {
    const updatedSubscription = { ...this.subscription.value } as SubscriptionInfo;
    updatedSubscription.details.current_active_employees += 1;
    this.subscription.next(updatedSubscription);
  };

  public decrementEmployeeCount = () => {
    const updatedSubscription = { ...this.subscription.value } as SubscriptionInfo;
    updatedSubscription.details.current_active_employees -= 1;
    this.subscription.next(updatedSubscription);
  };

  validateCoupon(code: string): Observable<any> {
    if (!code) {
      throw new Error('promo code missing.');
    }
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.get<CouponResponse>(this.couponUrl, {
          headers: getHeaders(t),
          params: { coupon: code.toUpperCase() },
        })
      )
    );
  }

  getBillingPortalUrl(referral: string): Observable<string> {
    return this.auth.idToken.pipe(
      mergeMap(t =>
        this.http.get<BillingUrlResponse>(this.billingPortalUrl, { headers: getHeaders(t), params: { referral } }).pipe(
          mergeMap(resp => {
            return of(resp.url);
          })
        )
      )
    );
  }

  getSubscriptionInfo(): Observable<SubscriptionResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of({ subscription: {} as SubscriptionInfo, status: '' });
        }
        return this.http.get<SubscriptionResponse>(this.purchaseUrl, { headers: getHeaders(t) });
      })
    );
  }

  processTransaction(planId: string, coupon: string, token: any, purchaseData: any): Observable<SubscriptionResponse> {
    if (coupon) {
      coupon = coupon.toUpperCase();
    }
    const cookieVal = this.cookieService.get('referral');
    const referral = cookieVal !== '' ? cookieVal : null;
    const data = { planId, coupon, token, referral, data: purchaseData };
    return this.auth.idToken.pipe(
      mergeMap(t => this.http.post<SubscriptionResponse>(this.purchaseUrl, data, { headers: getHeaders(t) }))
    );
  }

  getPlans(): Observable<PlansResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => this.http.get<PlansResponse>(this.plansUrl, { headers: getHeaders(t) }))
    );
  }
}

class PlansResponse {
  status: string;
  plans: Plan[];
}

class Plan {
  id: string;
  amount: number;
  interval: string;
  currency: string;
  description?: string;
  plan_name?: string;
  items?: string[];
  trialDays?: number;
}

class CouponResponse {
  status: string;
  message?: string;
  coupon: Coupon;
}

class Coupon {
  id: string;
  trial_period: number;
}

class SubscriptionResponse {
  status: string;
  message?: string;
  subscription?: SubscriptionInfo;

  constructor({ status, message, subscription }: Partial<SubscriptionResponse> = {}) {
    this.status = status;
    this.message = message;
    this.subscription = new SubscriptionInfo(subscription);
  }
}

class BillingUrlResponse {
  status: string;
  message?: string;
  url?: string;
}

class SubscriptionInfo {
  is_valid: boolean;
  status: string;
  freeTrialEligible: boolean;
  iap: IAPDetail[];
  sub: SubscriptionDetail;
  subscriptionType: string;
  planType: string;
  planName: string;
  willCancelOn?: string;
  details: {
    ach_capable: boolean;
    current_active_employees: number;
    max_employees: number;
    name: string;
  };

  constructor({
    is_valid,
    status,
    freeTrialEligible,
    iap,
    sub,
    subscriptionType,
    planType,
    planName,
    willCancelOn,
  }: Partial<SubscriptionInfo> = {}) {
    this.is_valid = is_valid;
    this.status = status;
    this.freeTrialEligible = freeTrialEligible || false;
    this.iap = iap;
    this.sub = sub;
    this.subscriptionType = subscriptionType;
    this.planType = planType;
    this.planName = planName ?? '';
    this.willCancelOn = willCancelOn;
  }

  canResubscribe(): boolean {
    return this.is_valid === false || this.willCancelOn != null;
  }

  getMessage(): string {
    // console.log(this);
    let hasStripe = this.sub;
    let stripeIsValid = hasStripe && this.sub.is_valid;
    let hasIap = this.iap && this.iap.length;
    let iapIsValid = hasIap && this.iap[0].is_valid;

    if (!hasStripe && !hasIap) {
      return `<strong>Select the right full-service plan for you. There are no sign-up fees nor year-end fees with active, ongoing subscriptions.</strong>`;
    }

    if (this.is_valid) {
      if (stripeIsValid) {
        return this.stripMessage();
      }

      if (iapIsValid) {
        return this.iapMessage(this.iap[0]);
      }
    } else {
      if (hasStripe) {
        return this.stripMessage();
      }

      if (hasIap) {
        return this.iapMessage(this.iap[0]);
      }
    }
  }

  iapMessage(iap: IAPDetail): string {
    const date = iap.periodEnd;

    const planName = this.planName ? ` ${this.planName}` : '';

    if (this.status === 'active') {
      if (this.willCancelOn) {
        return `Your Apple AppStore subscription to Nest Payroll, now canceled, is paid through <strong>${moment(
          this.willCancelOn
        ).format('M/D')}</strong>.
                If you subscribe to one of our other plans, the balance of your Apple subscription will be applied as a trial period.`;
      } else {
        return `Your Nest Payroll ${this.planName} subscription is active,
                thank you!\nYour account is paid through <strong>${moment(date).format('M/D')}</strong>
                and is billed through AppStore.`;
      }
    } else if (this.status === 'canceled') {
      const endDate = date;
      return `Your Nest Payroll${planName} subscription was canceled on
              <strong>${moment(endDate).format('M/D')}</strong>. Tap Subscribe to reactivate your account.`;
    }
  }

  stripMessage(): string {
    const date = this.sub.current_period_end;
    const price = '$' + this.sub.plan.amount / 100;

    if (this.status === 'active') {
      return `Your Nest Payroll ${this.planName} subscription is active,
              thank you!\nYour account is paid through <strong>${moment(date).format('M/D')}</strong>
              and is billed <strong>${price}</strong> per month`;
    } else if (this.status === 'trialing') {
      const trialStart = this.sub.trial_start;
      const trialEnd = this.sub.trial_end;

      let retString = `Thank You!\nYour Nest Payroll ${this.planName} subscription trial period starts <strong>${moment(
        trialStart
      ).format('M/D')}</strong>
      and goes until <strong>${moment(trialEnd).format('M/D')}</strong>.`;

      if (this.sub.cancel_at_period_end === true) {
        retString += 'Enjoy your account until then end of your trial period. You will no longer be charged.';
      } else {
        retString += `You will be billed <strong>${price}</strong> per month after the trial period ends.`;
      }
      return retString;
    } else if (this.status === 'canceled') {
      const endDate = this.sub.canceled_at || date;
      return `Your Nest Payroll ${this.planName} subscription was canceled on
              <strong>${moment(endDate).format('M/D')}</strong>. Tap Subscribe to reactivate your account.`;
    } else if (this.status === 'past_due') {
      return `Your Nest Payroll ${this.planName} subscription is past due on
              <strong>${moment(date).format(
                'M/D'
              )}</strong>. Tap Billing Portal to update your Credit Card information.`;
    }
  }
}

class IAPDetail {
  appVersion: string;
  bundleId: string;
  environment: string;
  isIntroductory: boolean;
  isTrial: boolean;
  is_valid: boolean;
  orderId: string;
  originalStart: string;
  periodStart: string;
  periodEnd: string;
  productId: string;
  status: string;
}

class SubscriptionDetail {
  is_valid: boolean;
  start: Date;
  current_period_start: Date;
  current_period_end: Date;
  canceled_at?: Date;
  cancel_at_period_end?: boolean;
  trial_start?: Date;
  trial_end?: Date;
  livemode: boolean;
  plan: Plan;
}

export {
  SubscriptionService,
  SubscriptionResponse,
  SubscriptionInfo,
  SubscriptionDetail,
  IAPDetail,
  PlansResponse,
  Plan,
  CouponResponse,
  Coupon,
};
