import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService, Role } from './auth.service';
import { getHeaders } from '../utils/http';

@Injectable({
  providedIn: 'root',
})
export class ActivationService {
  private url = `https://${environment.firebase.server}/app/v1/activation`;

  constructor(private auth: AuthService, private http: HttpClient) {}

  getActivation(): Observable<ActivationResponse> {
    return this.performAction({});
  }

  sendActivation(key: string, referral: any): Observable<ActivationResponse> {
    if (!key) {
      throw new Error('must provide a key');
    }

    const options = { key, referral };
    return this.performAction(options);
  }

  private performAction(options: any): Observable<ActivationResponse> {
    return this.auth.idToken.pipe(
      mergeMap(t => {
        if (!t) {
          return of(null);
        }
        return this.http.get<ActivationResponse>(this.url, { headers: getHeaders(t), params: options });
      })
    );
  }
}

class ActivationResponse {
  status: string;
  message?: string;
  activation?: Activation;
}

class Activation {
  activation_status: string;
  refreshedOn: Date;
  unlocks: any;
  claims: {
    role: Role;
  };
}
