import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import moment from 'moment';
import { getLastStartOfWeek } from '../../utils/date';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  imports: [NgbDatepickerModule, FormsModule, JsonPipe],
  styleUrls: ['./date-range.component.css'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true,
    },
  ],
})
export class DateRangeComponent implements ControlValueAccessor {
  @Input() startDayOfWeek: any;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  startDate: NgbDateStruct;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private config: NgbDatepickerConfig
  ) {
    config.firstDayOfWeek = 0;

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    const today = moment();

    const sixMonthsAgo = today.clone().subtract(6, 'months');

    // Set min date to 6 months ago
    this.minDate = {
      year: sixMonthsAgo.year(),
      month: sixMonthsAgo.month() + 1, // NgbDateStruct uses 1-based months
      day: sixMonthsAgo.date(),
    };

    this.maxDate = {
      year: today.year(),
      month: today.month() + 1, // NgbDateStruct uses 1-based months
      day: today.date(),
    };

    const lastMonth = moment().subtract(1, 'months');
    this.startDate = {
      year: lastMonth.year(),
      month: lastMonth.month() + 1, // NgbDateStruct uses 1-based months
      day: 1,
    };
  }

  onDateSelection(date: NgbDate) {
    if (!this.startDayOfWeek) {
      console.error('startDayOfWeek must be passed as a prop to the date-range component');
      return;
    }

    const selectedDate = moment({ year: date.year, month: date.month - 1, day: date.day });

    // Get the last occurrence of the specified weekday more than one week ago
    let fromDate = getLastStartOfWeek(this.startDayOfWeek);

    // If the selected date is before the fromDate, adjust fromDate to be the same week as the selected date
    if (selectedDate.isBefore(fromDate)) {
      const daysDifference = selectedDate.day() - fromDate.day();
      fromDate = selectedDate.clone().subtract(daysDifference, 'days');
    }

    // Calculate toDate as 6 days after the fromDate
    const toDate = fromDate.clone().add(6, 'days');

    // Convert to NgbDate format for the date-picker component
    this.fromDate = new NgbDate(fromDate.year(), fromDate.month() + 1, fromDate.date());
    this.toDate = new NgbDate(toDate.year(), toDate.month() + 1, toDate.date());

    // Emit the date range
    this.onChange({ fromDate: this.fromDate, toDate: this.toDate });
    this.onTouched();
  }

  onInputFromDate(value: string) {
    this.fromDate = this.validateInput(this.fromDate, value);
    this.onChange({ fromDate: this.fromDate, toDate: this.toDate });
    this.onTouched();
  }

  onInputToDate(value: string) {
    this.toDate = this.validateInput(this.toDate, value);
    this.onChange({ fromDate: this.fromDate, toDate: this.toDate });
    this.onTouched();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.fromDate = obj.fromDate;
      this.toDate = obj.toDate;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
