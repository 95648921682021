import { Component, Input, ChangeDetectorRef, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { getStorage, ref } from 'firebase/storage';
import { uploadBytesResumable } from '@angular/fire/storage';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() path: string;
  @Input() maxFileSize: number;
  @Input() formControl: any;

  filename = '';
  requiredFileType: string;
  uploadProgress = 0;

  constructor(private http: HttpClient, private changeDetectorRef: ChangeDetectorRef) {}

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  async onFileSelected(event: any) {
    if (!this.path) {
      throw new Error('Path is required');
    }

    const file: File = event.target.files[0];

    if (file) {
      this.filename = file.name;

      const storage = getStorage();
      const storageRef = ref(storage, this.path);

      const buffer = await file.arrayBuffer();

      const uploadTask = uploadBytesResumable(storageRef, buffer);

      uploadTask.on(
        'state_changed',
        snapshot => {
          this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }

          this.changeDetectorRef.detectChanges();
        },
        error => {
          console.error(error);
        },
        () => {
          this.changeDetectorRef.detectChanges();
          const uploadedFileInfo = { fileName: this.filename, path: this.path }; // Example of file info, adjust as necessary

          console.log('File uploaded successfully', uploadedFileInfo);
          this.onChange(this.path);

          this.formControl.setValue(this.path || 'uploaded');
          this.formControl.markAsTouched();
          this.formControl.markAsDirty();
          this.formControl.setErrors(null);
        }
      );
    }
  }
}
